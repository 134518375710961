(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AirDialogController', AirDialogController);

    AirDialogController.$inject = ['$timeout', '$stateParams', '$scope', '$rootScope', '$http', '$state', '$location', '$uibModal', '$translate', '$uibModalInstance',
        'Principal', 'Guide', 'entity', 'WmsWarehouse', 
        'Booking', 'DateUtils', 'BookingCtns', 'OpsFactory', 
        'OpsVender', 'GuideCtns', 'OpsCompany', 'OpsReport', 'OpsCarrier', 'WmsIn', 
        'OpsFiles', 'DataUtils',  'OpsCost', 'Email', 'GuideTemplate', 'GridState'
    ];

    function AirDialogController($timeout, $stateParams, $scope, $rootScope, $http, $state, $location, $uibModal, $translate, $uibModalInstance,
        Principal, Guide, entity, WmsWarehouse, 
        Booking, DateUtils, BookingCtns, OpsFactory,
        OpsVender, GuideCtns, OpsCompany, OpsReport, OpsCarrier, WmsIn, 
        OpsFiles, DataUtils, OpsCost, Email, GuideTemplate, GridState) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                initGuide();
            });
        }
        var activeTabCopy = $rootScope.GUIDEACTIVETAB == null?0:$rootScope.GUIDEACTIVETAB;
        $scope.activeTab = activeTabCopy;

        $scope.guide = entity;
        var guideCopy = {};
        var path = $location.path();
        $timeout(function() {
            loadData();            
            $translate.refresh();    
        });
        $scope.isVMed = false;
        $scope.isVFinal = false;
        $scope.isVClient= false;
        function initVirtual () {
            if($scope.guide.isVirtual){
                if (!$rootScope.account.companyId){
                    $scope.isVClient = true;
                }
                else if(!$scope.guide.medCompanyId || $scope.guide.medCompanyId == $rootScope.account.companyId){
                    $scope.isVMed = true;
                }
                else {
                    $scope.isVFinal = true;
                }
            }   
        } 
        $scope.cargoIsOut = false;
        $scope.cargoIsIn = false;
        $scope.jobNumCopy = null;
        var initGuide = function() {
            initVirtual();
            $scope.jobNumCopy = $scope.guide.jobNum;
            if($scope.guide.otherTimeTwo){
                $scope.cargoIsOut = true;
            }
            else {
                $scope.cargoIsOut = false;
            }
            if($scope.guide.otherTimeOne){
                $scope.cargoIsIn = true;
            }
            else {
                $scope.cargoIsIn = false;
            }
            if ($scope.guide.id 
                && $rootScope.account.mustAudit 
                && isOpDocOnly() 
                && $scope.guide.createdBy != $rootScope.account.id
                && $scope.guide.checkStatus != 'approved') {
                layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                goBack();
                return;
            }
            if (!$scope.guide.guideShipperBl) {
                $scope.guide.guideShipperBl = $rootScope.account.blShow;
            }

            $scope.lanes = $rootScope.GET_LINES("AIR");

            if (!$scope.guide.polName) {
                angular.forEach($rootScope.OPSPORTS.airports, function(data) {
                    if (data.name == $rootScope.account.defaultPol) {
                        $scope.guide.polName = data.name;
                        $scope.guide.booking.polName = data.name;
                    }
                })
            }
            if (!$scope.guide.issuePlace) {
                $scope.guide.issuePlace = $rootScope.account.defaultPol;
            }
            if (!$scope.guide.booking) {
                $scope.guide.booking = {};
            }

            if (!$scope.guide.guideDocs) {
                $scope.guide.guideDocs = [];
            }
            if (!$scope.guide.guideCtns) {
                $scope.guide.guideCtns = [];
            }
            /*初始化创建人*/
            if (!$scope.guide.createdBy || !$scope.guide.companyId) {
                $scope.guide.createdBy = $rootScope.account.id;
                if(!$scope.isVMed){
                    $scope.guide.companyId = $rootScope.account.companyId;
                }
            }
            if (!$scope.guide.id && !$scope.guide.salesName && $scope.salesFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.salesId = $rootScope.account.id;
                $scope.guide.salesName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.salesId = $rootScope.account.id;
                $scope.guide.team = $rootScope.account.team;
                $scope.guide.branch = $rootScope.account.branch;
            }
            if (!$scope.guide.id && !$scope.guide.cusName && $scope.cusFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.cusId = $rootScope.account.id;
                $scope.guide.cusName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.cusId = $rootScope.account.id;
            }
            if (!$scope.guide.id && !$scope.guide.opName && $scope.opFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.opName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.opId = $rootScope.account.id;
                $scope.guide.opId = $rootScope.account.id;
            }
            if (!$scope.guide.id && !$scope.guide.docName && $scope.docFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.docId = $rootScope.account.id;
                $scope.guide.docName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.docId = $rootScope.account.id;
            }
            if (!$scope.guide.id && !$scope.guide.checkByName && $scope.marketFilter($rootScope.account) && !$scope.isVFinal) {
                $scope.guide.checkById = $rootScope.account.id;
                $scope.guide.checkByName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            if(!$scope.guide.carrierOne && $scope.guide.booking.carrierCode){
                $scope.guide.carrierOne = $scope.guide.booking.carrierCode;
            }
            if(!$scope.guide.booking.divVolBy){
                $scope.guide.booking.divVolBy = 6000;
            }
            if(!$scope.guide.decCarriage){
                $scope.guide.decCarriage = "N.V.D";
            }
            if(!$scope.guide.amountIns){
                $scope.guide.amountIns = "NIL";
            }
            if(!$scope.guide.ncvStr){
                $scope.guide.ncvStr = "N.C.V";
            }
            if(!$scope.guide.booking.decCarriage){
                $scope.guide.booking.decCarriage = "N.V.D";
            }
            if(!$scope.guide.booking.amountIns){
                $scope.guide.booking.amountIns = "NIL";
            }
            if(!$scope.guide.booking.ncvStr){
                $scope.guide.booking.ncvStr = "N.C.V";
            }
            if(!$scope.guide.freightTerm){
                $scope.guide.freightTerm = "PPD";
            }
            if(!$scope.guide.booking.freightTerm){
                $scope.guide.booking.freightTerm = "PPD";
            }
            if(!$scope.guide.otherVal){
                $scope.guide.otherVal = "PPD";
            }
            if(!$scope.guide.booking.otherVal){
                $scope.guide.booking.otherVal = "PPD";
            }
            if(!$scope.guide.unit){
                $scope.guide.unit = "K";
            }
            if(!$scope.guide.booking.unit){
                $scope.guide.booking.unit = "K";
            }
            if(!$scope.guide.pkgsAndType){
                if($scope.guide.pkgNum){
                    $scope.guide.pkgsAndType = $scope.guide.pkgNum + " " +  $scope.guide.booking.pkgType;
                }
                else if ($scope.guide.booking.pkgNum){
                    $scope.guide.pkgsAndType = $scope.guide.booking.pkgNum + " " +  $scope.guide.booking.pkgType;
                }
            }
            if(!$scope.guide.airGw){
                if($scope.guide.gw){
                    $scope.guide.airGw = $scope.guide.gw;
                }
                else if($scope.guide.booking.gw){
                    $scope.guide.airGw = $scope.guide.booking.gw;
                }                
            }            
            if(!$scope.guide.gwVol){
                $scope.getVolumeContent($scope.guide, "gwVol");
            }
            if(!$scope.guide.booking.gwVol){
                $scope.getVolumeContent($scope.guide.booking, "gwVol");
            }
            
            if(!$scope.guide.awbWeight){
                if($scope.guide.chargeWeight){
                    $scope.guide.awbWeight = $scope.guide.chargeWeight;
                    $scope.countWeight('MAWB');
                }
            }
            if(!$scope.guide.booking.awbWeight){
                if($scope.guide.chargeWeight){
                    $scope.guide.booking.awbWeight = $scope.guide.chargeWeight;
                    $scope.countWeight('HAWB');
                }
            }

            $scope.updateChargesTtl('MAWB');
            $scope.updateChargesTtl('HAWB');   
            if ($scope.guide.status == "new" && $scope.guide.booking.id) {
                OpsFiles.viewBookinSendedFile({
                    guideId: $scope.guide.id
                }, function(response) {
                    var file = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    if (file.size == 0) {
                        return;
                    }
                    var fileURL = URL.createObjectURL(file);
                    var title = $translate.instant('autopsApp.guide.detail.booking');
                    layer.open({
                        type: 2,
                        area: ['900px', '680px'],
                        maxmin: true,
                        title: title,
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        btn: [$translate.instant('autopsApp.guide.layer.acceptBooking'), $translate.instant('entity.action.back'), $translate.instant('autopsApp.guide.layer.declineBooking')],
                        content: fileURL,
                        yes: function(index, layero) {
                            $scope.guide.booking.status = "Confirmed";
                            $scope.guide.status = "Uncommitted";
                            $scope.guide.booking.lastModifiedTime = new Date();
                            Guide.update($scope.guide, onSaveSuccess, onSaveError);
                            $translate.refresh();
                        },
                        cancel: function(index) {
                            layer.close(index);
                            goBack();
                        },
                        btn3: function(index, layero) {
                            $scope.guide.booking.status = "Declined";
                            $scope.guide.status = "Declined";
                            $scope.guide.booking.lastModifiedTime = new Date();
                            Guide.update($scope.guide, onSaveSuccess, onSaveError);
                            goBack();
                        }
                    });
                }, function(error) {
                    //layer.msg($translate.instant('autopsApp.guide.layer.noBooking'));
                });
            };
            guideCopy = angular.copy($scope.guide);
            if (guideCopy.jobNum) {
                document.title = guideCopy.jobNum;
            } else {
                document.title = "New Export Biz";
            }          
 
            if($rootScope.GUIDEACTIVETAB == 2 || $rootScope.GUIDEACTIVETAB == 3){
                $scope.getHblsCount();
            }   
            $scope.guideCneeSearch = "";
            $scope.guideNotifySearch = "";
            $scope.guideShipperSearch = "";
            $rootScope.VOID_MARK($scope.guide);
            $rootScope.AUTOFULLNODE($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
        }
        $scope.loadGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.refreshAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {
            });
        }

        var loadGuide = function(refreshPWV) {
            Guide.get({
                id: $scope.guide.id
            }, function(result) {
                $scope.guide = result;
                initGuide();
                if(refreshPWV){
                    $scope.updateVolGw();
                    $scope.save();
                }
            });
        }

        var hblsAmtCopy = null;
        var oldGuideId = null;
        var loadData = function() {
            if (path.indexOf("dialog") >= 0 || $rootScope.id == "view") {
                initGuide();
            } else if (path.indexOf("copy") >= 0 || $rootScope.id == "copy") {
                hblsAmtCopy = $scope.guide.expressLabelId;
                $scope.guide.expressLabelId = null;
                oldGuideId = $scope.guide.id;

                $scope.guide = DataUtils.clearCopyGuide($scope.guide);

                var now = new Date();
                $scope.guide.createdTime = now;
                $scope.guide.lastModifiedTime = now;
                $scope.guide.createdBy = $rootScope.account.id;
                if(!$scope.guide.isVirtual){
                    $scope.guide.companyId = $rootScope.account.companyId;
                } 
                $scope.guide.checkStatus = "notChecked";     
                if ($rootScope.IS_OP_CHECK()) {
                    $scope.guide.status = "new";
                } else {
                    $scope.guide.status = "Uncommitted";
                }
                $rootScope.COPY_GUIDE_CHOOSE($scope.guide);
                initGuide();             
            } else {
                var now = new Date();
                if (angular.isDefined($rootScope.account)) {
                    $scope.guide = {
                        createdBy: $rootScope.account.id,
                        companyId: $rootScope.account.companyId,
                        createdTime: now,
                        shipmentType: $rootScope.newShipmentType,
                        booking: {
                            divVolBy: 6000,
                            shipmentType: $rootScope.newShipmentType,
                            bookingCtns: [],
                            bookingPrices: [],
                            bookingTrucks: [] 
                        },
                        bookingCustoms: [],
                        guideRates: [],
                        guideDocs: [],
                        guideCtns: [],
                        bizType: "direct",
                        checkStatus: "notChecked",
                        isApplied: false,
                        isExport: true,
                        mblOnly: true,
                        allBilled: false,
                        chargeByCbm: false,
                        isVoid: false,
                        isClosed: false,
                        isWood: false,
                        isTemplate: false,
                        isLocked: false,
                        issueFeederHbl: false,
                        etdLocked: false,
                        trackingFinished: false,
                        hidenCost: false,
                        hidenSelling: false,
                        vat: false,
                        incomeTax: false,
                        lastModifiedTime: now
                    };
                    if ($rootScope.IS_OP_CHECK()) {
                        $scope.guide.status = "new";
                    } else {
                        $scope.guide.status = "Uncommitted";
                    }
                    initGuide();
                }
            }
        }

        var isOpDocOnly = function() {            
            if(path.indexOf("expPop") >= 0){
                if ($rootScope.account.id == $scope.guide.salesId || $rootScope.account.id == $scope.guide.cusId) {
                    return false;
                }                
                if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                    return true;
                }
                var authorities = ["ROLE_BOSS", "ROLE_SALES", "ROLE_CS", "ROLE_MARKET"];
                for (var i = 0; i < authorities.length; i++) {
                    if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                        return false;
                    }
                }
                return true;
            }
            if($rootScope.choosedRole == "ROLE_OPERATOR" || $rootScope.choosedRole == "ROLE_DOCUMENT" || ($rootScope.choosedRole && $rootScope.choosedRole.indexOf("TABLE") !== -1)){
                return true;
            }
            return false;           
        };
        var isOpOrSales = function() {
            var authorities = ["ROLE_BOSS", "ROLE_DOCUMENT", "ROLE_MARKET", "ROLE_OPERATOR", "ROLE_SALES", "ROLE_CS"];
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        $scope.payerData = {
            value: []
        };

        var payerChooseOneForEmailIndex = 0;

        $scope.opNodes = new Array(
            "",
            $translate.instant('autopsApp.guide.node.booking'),
            $translate.instant('autopsApp.guide.node.pickingUp'),
            $translate.instant('autopsApp.guide.node.operating'),
            $translate.instant('autopsApp.guide.node.cargoOut'),
            $translate.instant('autopsApp.guide.node.customDoing'),
            $translate.instant('autopsApp.guide.node.customDone'),
            $translate.instant('autopsApp.guide.node.docReturn'),
            $translate.instant('autopsApp.guide.node.finished'));

        $scope.opFilter = function(item) {
            return item.authorities.indexOf("ROLE_OPERATOR") !== -1;
        };
        $scope.docFilter = function(item) {
            return item.authorities.indexOf("ROLE_DOCUMENT") !== -1;
        };
        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.cusFilter = function(item) {
            return item.authorities.indexOf("ROLE_CS") !== -1;
        };
        $scope.overseasCusFilter = function(item) {
            return item.authorities.indexOf("ROLE_OVERSEAS_CS") !== -1;
        };        
        $scope.marketFilter = function(item) {
            return item.authorities.indexOf("ROLE_MARKET") !== -1;
        };
        $scope.finFilter = function(item) {
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };
        $scope.bookingByFilter = function(item) {
            return item.authorities.indexOf("BOOKING_BY") !== -1 || item.authorities.indexOf("ROLE_WAREHOUSE") !== -1;
        };
        $scope.truckByFilter = function(item) {
            if($rootScope.account.companyId == 647){
                return item.authorities.indexOf("TRUCK_BY") !== -1 || item.authorities.indexOf("ROLE_SALES") !== -1;
            }
            return item.authorities.indexOf("TRUCK_BY") !== -1;
        };
        $scope.customByFilter = function(item) {
            return item.authorities.indexOf("CUSTOM_BY") !== -1;
        };

        $scope.hidenAgent = function() {
            if ($scope.guide && !$scope.guide.deliverySecret) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            var authorities = ["ROLE_OPERATOR", "ROLE_ACCOUNT", "ROLE_DOCUMENT", "ROLE_BOSS"];
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) != -1) {
                    return false;
                }
            }
            return true;
        };

        $scope.hidenSelling = function() {
            if ($scope.guide && !$scope.guide.hidenSelling) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWSELLING") == -1;
        };

        $scope.hideCost = function() {
            if ($scope.guide && !$scope.guide.hidenCost) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWCOST") == -1;
        };


        $scope.files = new Array();
        $scope.clientFiles = new Array();

        var goBack = function() {
            $uibModalInstance.close(guideCopy);
            
        }

        $scope.goBack = function() {
            if (!angular.equals($scope.guide, guideCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    layer.close(index);
                    goBack();
                }, function() {});
                return;
            } else {
                goBack();
            }
        }

        $scope.deleteCtn = function(bookingCtn) {
            if(!bookingCtn.id || !$scope.guide.id){
                var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.booking.bookingCtns.splice(index, 1);
                };
                return;
            }
            if($scope.guide.isClosed){return;}
            if (!angular.equals(guideCopy, $scope.guide) && $scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var loadIndex = layer.load(1, {shade: 0.3});
            BookingCtns.delete({
                id: bookingCtn.id
            }, function(){
                layer.close(loadIndex);  
                loadGuide();
            }, function(result){
                layer.close(loadIndex);  
                if(result.status == 409){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});                        
                }
                else {
                    layer.msg($translate.instant('global.delete_fail'));
                }
            });
        };
        function checkPodDest(){
            if(!$scope.guide.destId || !$scope.guide.podId){
                return;
            }
            if($scope.guide.destId != $scope.guide.podId){
                $scope.guide.porId = $scope.guide.destId;
                if(!$scope.guide.carrierTwo){
                    $scope.guide.carrierTwo = $scope.guide.booking.carrierCode;
                }
            }
            else {
                $scope.guide.porId = null;
                $scope.guide.carrierTwo = null;
            }
        }
        function checkBookingPodDest(){
            if(!$scope.guide.booking.destId || !$scope.guide.booking.podId){
                return;
            }
            if($scope.guide.booking.destId != $scope.guide.booking.podId){
                $scope.guide.booking.porId = $scope.guide.booking.destId;
                if(!$scope.guide.carrierTwo){
                    $scope.guide.carrierTwo = $scope.guide.booking.carrierCode;
                }
            }
            else {
                $scope.guide.porId = null;
                $scope.guide.carrierTwo = null;
            }
        }

        $scope._selected = function($item, which) {
            switch (which) {
                case "gporChoose":
                    // $scope.guide.porId = $item.code;
                    break;
                case "gpolChoose":
                    $scope.guide.polId = $item.code;
                    $scope.guide.polRegion = $item.region;
                    break;
                case "gpodChoose":
                    $scope.guide.podId = $item.code;
                    if(!$scope.guide.destCountryCode){
                        // $scope.guide.destId = $item.code;
                        // $scope.guide.destName = $item.name;
                        // $scope.guide.podRegion = $item.region;
                        $scope.guide.destCountryCode = $item.countryCode;
                    }
                    if(!$scope.guide.podRegion){
                        $scope.guide.podRegion = $item.region;
                    } 
                    checkPodDest();
                    break;
                case "gdestChoose":
                    $scope.guide.destId = $item.code;
                    // if(!$scope.guide.podId){
                    //     $scope.guide.podId = $item.code;
                    //     $scope.guide.podName = $item.name;
                    // }                    
                    $scope.guide.podRegion = $item.region;
                    $scope.guide.destCountryCode = $item.countryCode;
                    checkPodDest();
                    break;
                case "gdeliverytoChoose":
                    // $scope.guide.deliverytoId = $item.code;
                    break;
                case "podChoose":
                    $scope.guide.booking.podId = $item.code;
                    // if(!$scope.guide.booking.destId || !$scope.guide.id){
                    //     $scope.guide.booking.destId = $item.code;
                    //     $scope.guide.booking.destName = $item.name;
                    // }
                    checkBookingPodDest();
                    break;
                case "porChoose":
                    // $scope.guide.booking.porId = $item.code;
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;
                    // if(!$scope.guide.booking.podId){
                    //     $scope.guide.booking.podId = $item.code;
                    //     $scope.guide.booking.podName = $item.name;
                    // }
                    checkBookingPodDest();
                    break;
                case "deliverytoChoose":
                    // $scope.guide.booking.deliverytoId = $item.code;
                    break;
                case "polChoose":
                    $scope.guide.booking.polId = $item.code;
                    break;
            }
        }

        $scope.copyBookingPorts = function() {
            $scope.guide.serviceTerm = $scope.guide.booking.serviceTerm;
            $scope.guide.porName = $scope.guide.booking.porName;
            $scope.guide.polName = $scope.guide.booking.polName;
            $scope.guide.podName = $scope.guide.booking.podName;
            $scope.guide.destName = $scope.guide.booking.destName;
            $scope.guide.deliveryTo = $scope.guide.booking.deliveryTo;

            $scope.guide.porId = $scope.guide.booking.porId;
            $scope.guide.polId = $scope.guide.booking.polId;
            $scope.guide.podId = $scope.guide.booking.podId;
            $scope.guide.destId = $scope.guide.booking.destId;
            $scope.guide.deliverytoId = $scope.guide.booking.deliverytoId;

            angular.forEach($rootScope.OPSPORTS.airports, function(data) {
                if ($scope.guide.booking.polId == data.code) {
                    $scope.guide.polRegion = data.region;
                }
                if ($scope.guide.booking.podId == data.code) {
                    $scope.guide.podRegion = data.region;
                }
            })
            $scope.guide.mark = $scope.guide.booking.mark;
            $scope.guide.pkgsAndType = $scope.guide.booking.pkgsAndType;
            $scope.guide.goodsDiscription = $scope.guide.booking.goodsDiscription;
            $scope.guide.gwVol = $scope.guide.booking.gwVol;
            $translate.refresh();
        }
        
        $scope.copyGuidePorts = function() {
            $scope.guide.booking.serviceTerm = $scope.guide.serviceTerm;
            $scope.guide.booking.porName = $scope.guide.porName;
            $scope.guide.booking.polName = $scope.guide.polName;
            $scope.guide.booking.podName = $scope.guide.podName;
            $scope.guide.booking.destName = $scope.guide.destName;
            $scope.guide.booking.deliveryTo = $scope.guide.deliveryTo;

            $scope.guide.booking.porId = $scope.guide.porId;
            $scope.guide.booking.polId = $scope.guide.polId;
            $scope.guide.booking.podId = $scope.guide.podId;
            $scope.guide.booking.destId = $scope.guide.destId;
            $scope.guide.booking.deliverytoId = $scope.guide.deliverytoId;

            $scope.guide.booking.mark = $scope.guide.mark;
            $scope.guide.booking.pkgsAndType = $scope.guide.pkgsAndType;
            $scope.guide.booking.goodsDiscription = $scope.guide.goodsDiscription;
            $scope.guide.booking.gwVol = $scope.guide.gwVol;
            $translate.refresh();
        }
        var onSaveSuccess = function(result) {
            saved = false;
            $scope.isSaving = false;
            $scope.guide = result;
            $scope.updateChargesTtl('MAWB');
            $scope.updateChargesTtl('HAWB');   
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            if($scope.guide.otherTimeTwo){
                $scope.cargoIsOut = true;
            }
            else {
                $scope.cargoIsOut = false;
            }            
            if($scope.guide.otherTimeOne){
                $scope.cargoIsIn = true;
            }
            else {
                $scope.cargoIsIn = false;
            }
            layer.closeAll();
            if(hblsAmtCopy){
                hblsAmtCopy = null;
                layer.confirm($translate.instant('autopsApp.guide.copyHblsConfirm', {hbls: hblsAmtCopy}), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    Guide.copyHbls({
                        oldId: oldGuideId,
                        newId: $scope.guide.id
                    }, function(){                        
                        layer.msg($translate.instant('global.copySuccess'));
                    })
                    layer.close(index);
                }, function() {});
            }
            else if (notDeliveriedAlert) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.newTips'));
            }
            else if (!$scope.guide.etd && $scope.guide.status != "Declined") {
                layer.msg($translate.instant('autopsApp.guide.home.noEtdAlert'));
            }
            else if (DateUtils.getDaysDiff($scope.guide.etd, $scope.guide.eta) < 0) {
                layer.msg($translate.instant('autopsApp.guide.etaLessThanEtd'));
            }
            else {
                layer.msg($translate.instant('global.messages.submitted'));
            }
            document.title = result.jobNum;            
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } 
            else if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            } 
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            } 
            else if (result.status == 423) {
                layer.msg($translate.instant('autopsApp.guide.jobNumConflictAlert'));
            } 
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }

            $translate.refresh();
        };

        var notDeliveriedAlert = false;
        $scope.save = function() {
            if ($scope.isSaving) {
                return;
            }            
            if ($rootScope.HAVENOAUTH("AU_AMEND_SHIPMENT")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_SHIPMENT'); 
                return;
            }
            if ($scope.guide.isVirtual && !$scope.guide.whId) {
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if (!$rootScope.IS_OP_CHECK() && $scope.guide.isLocked) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (angular.equals($scope.guide, guideCopy) && $scope.guide.id) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            };            
            // if($scope.guide.submited){
                var langName = $rootScope.RQ_CHECK($scope.guide);
                if(langName){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.requiredNullAlert', {param: langName}));
                    return;
                }
            // }
            

            $scope.isSaving = true;
            if (!$scope.guide.submited) {
                notDeliveriedAlert = true;
            } else {
                notDeliveriedAlert = false;
            }
            vendersInit();
            $scope.guide.booking.bookingToName = $rootScope.account.companyName;
            $rootScope.AUTOFULLNODE($scope.guide);
            Guide.update($scope.guide, onSaveSuccess, onSaveError);
        };

        var deliverySuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            layer.closeAll();
            if(hblsAmtCopy){
                hblsAmtCopy = null;
                layer.confirm($translate.instant('autopsApp.guide.copyHblsConfirm', {hbls: hblsAmtCopy}), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    Guide.copyHbls({
                        oldId: oldGuideId,
                        newId: $scope.guide.id
                    }, function(){
                        layer.msg($translate.instant('global.copySuccess'));
                    })
                    layer.close(index);
                }, function() {});
            }
            else {
                layer.msg($translate.instant('global.messages.submitted'));
            }  
            $translate.refresh();
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 2;
            guideReportDTO.reportType = 20;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO);

        };

        var deliveryError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } 
            else if (result.status === 403) {
                $scope.guide.status = guideCopy.status;
                layer.msg($translate.instant('global.messages.creditOverLimit'));
                $translate.refresh();
            } else {
                $rootScope.ALERT_ERROR(result);
            }
        };

        $scope.deliveryGuide = function() {
            if($scope.isSaving) {
                return;
            }            
            if(!$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.isVirtual && !$scope.guide.whId) {
                $scope.isSaving = false;
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            var langName = $rootScope.DELIVERY_RQ_CHECK($scope.guide);
            if(langName){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.requiredNullAlert', {param: langName}));
                return;
            }
            $scope.isSaving = true;
            vendersInit();
            $scope.guide.status = "Requested";
            if (!$scope.guide.createdBy || !$scope.guide.companyId) {
                $scope.guide.createdBy = $rootScope.account.id;
                $scope.guide.companyId = $rootScope.account.companyId;
            }
            var pageUrl = window.location.href;
            if(!pageUrl.endsWith("=")){
                pageUrl = pageUrl + "/dialog/" + $scope.guide.encodeId;
            }
            Guide.delivery({pageUrl: pageUrl}, $scope.guide, deliverySuccess, deliveryError);
        }

        $scope.isSame = function() {
            return angular.equals($scope.guide, guideCopy);
        }

        var vendersInit = function() {
            if ($scope.guide.warehouseVid && !$scope.guide.warehouseName) {
                $scope.guide.warehouseVid = null;
                $scope.guide.warehouseName = null;
            }
            if ($scope.guide.fumigationVid && !$scope.guide.fumigationName) {
                $scope.guide.fumigationVid = null;
                $scope.guide.fumigationName = null;
            }
            if ($scope.guide.insuranceVid && !$scope.guide.insuranceName) {
                $scope.guide.insuranceVid = null;
                $scope.guide.insuranceName = null;
            }
            if ($scope.guide.traderVid && !$scope.guide.traderName) {
                $scope.guide.traderVid = null;
                $scope.guide.traderName = null;
            }
            if ($scope.guide.deliveryId && !$scope.guide.deliveryName) {
                $scope.guide.deliveryId = null;
                $scope.guide.agentUserId = null;
                $scope.guide.deliveryName = null;
                $scope.guide.deliveryEmail = null;
                $scope.guide.deliverySecret = null;
            }
            if ($scope.guide.bookingToId && !$scope.guide.bookingToName) {
                $scope.guide.bookingToId = null;
                $scope.guide.bookingToName = null;
                $scope.guide.bookingToEmail = null;
            }
            if ($scope.guide.shipperId && !$scope.guide.shipperName) {
                $scope.guide.shipperId = null;
                $scope.guide.shipperName = null;
                $scope.guide.shipperUserId = null;
            }            
        }

        $scope.updateFreightTerm = function(term) {
            $scope.guide.freightTerm = term;
            if (term == "PPD") {
                $scope.guide.payableAt = $scope.guide.booking.polName;
            } else {
                $scope.guide.payableAt = "DESTINATION";
            }
            $translate.refresh();
        }

        $scope.updateSupervisor = function(item) {
            $scope.guide.checkById = item.id;
            $scope.guide.checkByName = item.name;
        };
        $scope.updateOperator = function(item) {
            $scope.guide.opName = item.name;
            $scope.guide.booking.opId = item.id;
            $scope.guide.opId = item.id;
        };
        $scope.updateDoc = function(item) {
            $scope.guide.docName = item.name;
            $scope.guide.booking.docId = item.id;
            $scope.guide.docId = item.id;
        };
        $scope.updateFin = function(item) {
            $scope.guide.finName = item.name;
            $scope.guide.booking.finId = item.id;
            $scope.guide.finId = item.id;
        };
        $scope.updateSales = function(item) {
            $scope.guide.salesName = item.name;
            $scope.guide.booking.salesId = item.id;
            $scope.guide.salesId = item.id;
            $scope.guide.team = item.team;
            $scope.guide.branch = item.branch;
        };
        $scope.updateCus = function(item) {
            $scope.guide.cusName = item.name;
            $scope.guide.cusId = item.id;
        };
        $scope.updateOverseasCus = function(item) {
            $scope.guide.overseasCs = item.name;
            $scope.guide.overseasCsId = item.id;
        };
        $scope.updateBookingBy = function(item) {
            $scope.guide.bookingBy = item.name;
            $scope.guide.bookingById = item.id;
        };
        $scope.updateTruckBy = function(item) {
            $scope.guide.truckBy = item.name;
            $scope.guide.truckById = item.id;
        };
        $scope.updateCustomBy = function(item) {
            $scope.guide.customBy = item.name;
            $scope.guide.customById = item.id;
        };
        $scope.addCtn = function() {
            $scope.guide.booking.bookingCtns.push({});
            $translate.refresh();
        };

        $scope.isWood = false;
        $scope.woodenTxt = function() {
            if ($scope.guide.isWood) {
                $scope.guide.woodenStatement = "THIS SHIPMENT HAS BEEN FUMIGATED AND HEATED.";
            } else {
                $scope.guide.woodenStatement = "NO WOODEN PRODUCE HAS BEEN USED IN PACKING.";
            }
        }

        $scope.generateSayCtns = function(isHbl) {
            var sayPkgs = isHbl?DataUtils.getNumFromStr($scope.guide.booking.pkgsAndType):DataUtils.getNumFromStr($scope.guide.pkgsAndType);
            if(!sayPkgs){
                sayPkgs = $scope.guide.pkgNum;
            }
            if (!sayPkgs) {
                layer.msg($translate.instant('global.messages.sayCtnsFailed'));
                return;
            }
            $scope.guide.sayCtns = "SAY " + DataUtils.translate(sayPkgs.toString()) + " (" + sayPkgs + ") " + $scope.guide.booking.pkgType + " ONLY.";
        }

        $scope.generateMark = function() {
            // if (!$scope.guide.mark && $scope.guide.booking.mark && !$scope.guide.mblOnly) {
            //     $scope.guide.mark = angular.copy($scope.guide.booking.mark);               
            // }
            // $scope.guide.mark = angular.uppercase($scope.guide.mark);
        }
        $scope.generateGoodsDiscription = function() {
            if (!$scope.guide.goodsDiscription) {
                $scope.guide.goodsDiscription =  "CONSOL SHIPMENT AS MANIFEST ATT'D";
            }
            $scope.guide.goodsDiscription = angular.uppercase($scope.guide.goodsDiscription);
        }
        $scope.ggeneratePkgsAndType = function() {
            if (!$scope.guide.pkgNum || !$scope.guide.booking.pkgType || $scope.guide.pkgsAndType) {
                $scope.guide.pkgsAndType = angular.uppercase($scope.guide.pkgsAndType);
                return;
            }
            $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
        }

        $scope.generatePkgsAndType = function() {
            if (!$scope.guide.pkgNum || !$scope.guide.booking.pkgType || $scope.guide.booking.pkgsAndType) {
                $scope.guide.booking.pkgsAndType = angular.uppercase($scope.guide.booking.pkgsAndType);
                return;
            }
            $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
        }

        $scope.updatePkgsAndType = function(guidePkg) {
            if(!$scope.guide.mblConfirmedTime){
                $scope.guide.pkgsAndType = $scope.guide.pkgNum + " " + $scope.guide.booking.pkgType;
            }
            if(!$scope.guide.hblConfirmedTime){
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum + " " + $scope.guide.booking.pkgType;
            }         
            // if((!$scope.guide.booking.pkgNum || guidePkg) && !$scope.guide.otherTimeOne && !$scope.guide.masterId && !$scope.guide.parcelId){
            //     $scope.guide.booking.pkgNum = $scope.guide.pkgNum;
            // }
        }

        $scope.ggenerateBlAttachment = function() {
            if ($scope.guide.blAttachment) {
                $scope.guide.blAttachment = angular.uppercase($scope.guide.blAttachment);
                return;
            }
            if ($scope.guide.booking.blAttachment) {
                $scope.guide.blAttachment = angular.copy($scope.guide.booking.blAttachment);
            }
        }

        $scope.guideSendBooking = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.bookingToName;
            email.subject = $scope.guide.polName + " - " + $scope.guide.destName + "(" + $scope.guide.volumes + "By " + $scope.guide.carrier + "; ETD: "+ DateUtils.convertLocalDateToServer($scope.guide.etd) + ")--" + $translate.instant('entity.email.bookingSubject');
            email.content = $translate.instant('entity.email.bookingContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.bookingToId;
            entity.contactId = $scope.guide.bookingToContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.guideSendSo = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            var subjectNum = $scope.guide.booking.bookingNum;
            if (!subjectNum) {
                if ($scope.guide.soNum) {
                    subjectNum = $scope.guide.soNum;
                } else {
                    subjectNum = $scope.guide.jobNum;
                }
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.soSubject');
            email.content = $translate.instant('entity.email.soContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
                if(!angular.equals(guideCopy, $scope.guide)){
                    $scope.save();
                }               
            }, function(result) {});
        }

        $scope.demandSI = function(type) {
            Guide.getOnlineSiUrl({
                companyId: $scope.guide.companyId,
                guideId: $scope.guide.id
            }, function (result) {
                demandSI(type, result.onlineSiUrl);
            })  
        }
        var demandSI = function(type, onlineSiUrl) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            var subjectNum = $scope.guide.booking.bookingNum;
            if (!subjectNum) {
                if ($scope.guide.soNum) {
                    subjectNum = $scope.guide.soNum;
                } else {
                    subjectNum = $scope.guide.jobNum;
                }
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.siRequestSubject');
            email.content = "SO#: " + $scope.guide.soNum + ", " + $translate.instant('entity.email.siRequestContent') + DateUtils.convertDateTimeToString($scope.guide.siCutTime) + ". \n\n" + onlineSiUrl + "\n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);  
        }

        $scope.checkSi = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            var subjectNum = $scope.guide.soNum;
            if (!subjectNum) {
                subjectNum = $scope.guide.jobNum;
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.siCheckSubject');
            email.content = "SO#: " + $scope.guide.soNum + ", " + $translate.instant('entity.email.siCheckContent') + DateUtils.convertDateTimeToString($scope.guide.siCutTime) + ". \n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 620;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.submitSI = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.bookingToName;
            email.subject = $scope.guide.soNum + "--" + $translate.instant('entity.email.siSendSubject');
            email.content = $translate.instant('entity.email.siSendContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }

            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            if (type == "submitSI") {
                entity.code = 100;
            } else {
                entity.code = 90;
            }            
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.bookingToId;
            entity.contactId = $scope.guide.bookingToContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.saSend = function(type, venderId) {  
            var entity = {};
            entity.reportTemplates = $rootScope.REPORT_TEMPLATES;
            entity.shipmentType = $scope.guide.shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/reportTemplate/reportTemplate-choose.html',
                controller: 'ReportTemplateChooseController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reportTemplate');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                var reportTypeArray = new Array();
                reportTypeArray.push(result.hblCode);
                reportTypeArray.push(result.saCode);
                saSendEmailPop(type, venderId, reportTypeArray, result.cnCode);
            }, function(result) {

            });
        }

        var saSendEmailPop = function(type, venderId, reportTypeArray, cnCode) {
            var email = {};
            email.attachments = [];

            email.head = $scope.guide.deliveryName;
            email.subject = "HBL#: " + $scope.guide.hblNum + "/ MBL#: " + $scope.guide.mblNum + "--" + $translate.instant('entity.email.saSendSubject');
            var content = "TO: " + $scope.guide.deliveryName + "\n";
            var strs = [];
            if ($scope.guide.booking.shipperBl) {
                strs = $scope.guide.booking.shipperBl.split("\n");
                if (strs[0]) {
                    content += "SHIPPER: " + strs[0] + "\n";
                }
            }
            if ($scope.guide.booking.cneeBl) {
                strs = $scope.guide.booking.cneeBl.split("\n");
                if (strs[0]) {
                    content += "CNEE: " + strs[0] + "\n\n";
                }
            }

            content += "HBL#: " + $scope.guide.hblNum + "---" + $scope.guide.booking.freightTerm + "---" + $scope.guide.hblRlsWay + "\n";
            content += "MBL#: " + $scope.guide.mblNum + "---" + $scope.guide.freightTerm + "---" + $scope.guide.releaseWay + "\n\n";

            content += "Port of Loading#: " + $scope.guide.booking.polName + "\n";
            content += "Port of Discharge#: " + $scope.guide.booking.podName + "\n";
            content += "Port of Dest#: " + $scope.guide.booking.destName + "\n";
            if($scope.guide.booking.deliveryTo){
                content += "Place of Delivery#: " + $scope.guide.booking.deliveryTo + "\n\n";
            }   

            content += "\nVsl/ Voy: " + $scope.guide.vsl + "/ " + $scope.guide.voy + "\n";      
            content += "ETD/ ETA: " + DateUtils.convertLocalDateToServer($scope.guide.etd) + "/ " + DateUtils.convertLocalDateToServer($scope.guide.eta) + "\n\n";   

            content += "VOLUME: " + $scope.guide.volumes + "\n";   
            // var ctnStr = "";
            // angular.forEach($scope.guide.guideCtns, function(gctn){
            //     ctnStr += gctn.ctnNum + "/ " + gctn.ctnType + "; ";
            // })
            // if(ctnStr.length > 0){
            //     content += "CNTR NO.: " + ctnStr + "\n";    
            // }              
            content += "COMMODITY: \n" + $scope.guide.booking.goodsDiscription + "\n\n";     
            content += "IMPORTANT REMAINDER: \nCargo can be released ONLY AGAINST,\n1,EIGHTER ORIGINAL HOUSE BL COLLECT BACK FROM CONSIGNEE;\n";    
            content += "2.OR RECEIVING FORMAL TELEX RELEASE NOTICE FROM OUR AUTHORIZED TR CONTROLLER. \n";   
            content += "YOU & YOUR COMPANY will take up WHOLE LIABILITIES if releasing cargo WRONG.\n\n";  

            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = content + $rootScope.account.signatures[0].signature;
            }
            else {
                email.content = content;
            }
            
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = reportTypeArray;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.deliveryId;
            entity.contactId = $scope.guide.deliveryContactId;
            entity.saVenderId = venderId;
            entity.saCnCode = cnCode;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.isfSend = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.deliveryName;
            var subjectNum = $scope.guide.mblNum;
            if (!subjectNum) {
                subjectNum = $scope.guide.jobNum;
            }
            email.subject = subjectNum + "--ISF Sheet";
            email.content = "MBL#: " + $scope.guide.mblNum + ", Attached ISF Sheet FYI. \n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 80;          
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.deliveryId;
            entity.contactId = $scope.guide.deliveryContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.blSend = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            var subjectNum = $scope.guide.booking.bookingNum;
            if (!subjectNum) {
                if ($scope.guide.hblNum) {
                    subjectNum = $scope.guide.hblNum;
                } else {
                    subjectNum = $scope.guide.jobNum;
                }
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.blSendSubject');
            email.content = $translate.instant('entity.email.blSendContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }


        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            layer.closeAll();
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSendError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.authErrorAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.saSendOnline = function(incSubshipments) {
            if ($scope.guide.refGuideId) {
                layer.msg($translate.instant('autopsApp.guide.isSecond'));
                return;
            }
            if (!$scope.guide.deliveryId) {
                layer.msg($translate.instant('autopsApp.guide.home.choose_agent'));
                return;
            };
            if(!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var title = $translate.instant('autopsApp.guide.home.sa_send_title');
            var content = $scope.guide.deliveryName + $translate.instant('autopsApp.guide.home.sa_send_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                Guide.createImport({
                    incSubshipments: incSubshipments
                }, $scope.guide, function(){
                    loadGuide();
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
            }, function() {
                return;
            });
        }

        $scope.agentTr = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.deliveryName;
            email.subject = "HBL#: " + $scope.guide.hblNum + "/ MBL#: " + $scope.guide.mblNum + "--" + $translate.instant('entity.email.agentTrSubject');
            email.content = "HBL#: " + $scope.guide.hblNum + "\nMBL#: " + $scope.guide.mblNum + "\n\n" + $translate.instant('entity.email.agentTrContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }

            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 110;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.deliveryId;
            entity.contactId = $scope.guide.deliveryContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        var fileName_index = 0;

        /* ctns view */
        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
            $translate.refresh();
        };

        $scope.deleteGCtn = function(ctn) {
            if($scope.guide.isClosed){return;}
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            };
        };

        $scope.truckRequiredChange = function(required) {
            if (required) {
                if(!$scope.guide.truckBy){
                    $scope.guide.truckById = $scope.guide.opId;
                    $scope.guide.truckBy = $scope.guide.opName;
                }
            }
            else {
                $scope.guide.truckById = null;
                $scope.guide.truckBy = null;
            }
        }

        $scope.smSend = function(ctn) {
            var entity = {};
            entity.ctn = ctn;
            entity.guide = $scope.guide;
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-delete-dialog.html',
                controller: 'BookingCtnsDeleteController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        $scope.lockCtn = function(ctn) {
            if (ctn.locked) {
                ctn.locked = false;
            } else {
                ctn.locked = true;
            }
        }

        $scope.lockSI = function() {
            if ($scope.guide.booking.isLocked) {
                $scope.guide.booking.isLocked = false;
            } else {
                $scope.guide.booking.isLocked = true;
            }
            Booking.lockBooking({
                bookingId: $scope.guide.booking.id,
                isLocked: $scope.guide.booking.isLocked
            }, function(result, headers) {
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        $scope.getHblsCount = function() {
            Booking.getHblsCount({
                guideId: $scope.guide.id
            }, function(result, headers) {
                $scope.hbls = result.hbls;
            });
        }

        $scope.applyRelease = function() {
            guideCopy.isApplied = !guideCopy.isApplied;
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            };
            guideCopy.isApplied = !guideCopy.isApplied;
            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if(!$scope.guide.finId){
                layer.msg($translate.instant('autopsApp.guide.home.finNullAlert'));  
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if ($scope.guide.hblReleasedTime && $scope.guide.isApplied) {
                layer.msg($translate.instant('autopsApp.guide.home.blrelased'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            $scope.isSaving = true;
            Guide.applyRelease({
                guideId: $scope.guide.id,
                isApplied: $scope.guide.isApplied
            }, function(result, headers) {
                loadGuide();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        $scope.updateGwVol = function() {
            DataUtils.updateGuideCw($scope.guide);
        };

        $scope.priceChange = function() {
            var ttlPrice = 0;
            var ttlPieces = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(data) {
                if(data.unitPrice != null && data.pieces != null){
                    data.totalPrice = data.unitPrice * data.pieces;
                    data.totalPrice = DataUtils.round(data.totalPrice, 2);
                    ttlPrice = ttlPrice + data.totalPrice;
                }       
                if(data.pieces){
                    ttlPieces = ttlPieces + data.pieces;
                }
                
            })
            $scope.guide.cargoValue = ttlPrice + "USD";
            $scope.guide.pieces = DataUtils.round(ttlPieces, 2);
        };

        var onAuthError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.authErrorAlert'));
            } 
            else if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.guide.entrustConflict'));
            }
            else if (result.status === 403) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.authorizeBranch = function() {
            if (!$scope.guide.bookingToId) {
                layer.msg($translate.instant('autopsApp.guide.authorizeBranchNull'));
                return;
            }
            if(!$scope.guide.submited){
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            }
            var title = $translate.instant('autopsApp.guide.authorizeBranch');
            var content = $scope.guide.bookingToName + $translate.instant('autopsApp.guide.home.auth_send_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if($scope.guide.masterId == 0){
                    var sendSubOrderTip = $translate.instant('autopsApp.guide.home.isSendSubOrder');
                    layer.confirm(sendSubOrderTip, {
                        title: title,
                        btn: [$translate.instant('autopsApp.guide.home.includeSubOrder'), $translate.instant('autopsApp.guide.home.onlyMasterOrder')]
                    }, function(){
                        $scope.isSaving = true;
                        Guide.authorizeBranch({sendSubOrder: true}, $scope.guide, onSendSuccess, onAuthError);
                    }, function(){
                        $scope.isSaving = true;
                        Guide.authorizeBranch({sendSubOrder: false}, $scope.guide, onSendSuccess, onAuthError);
                    })
                }else{
                    $scope.isSaving = true;
                    Guide.authorizeBranch($scope.guide, onSendSuccess, onAuthError);
                }
            }, function() {
                return;
            });
        }
        var onSynSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            layer.msg($translate.instant('autopsApp.guide.synSuccess'));
        };

        var onSynFailed = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('autopsApp.guide.synFailed'));
        };
        $scope.synBranchGuide = function() {
            if($rootScope.HAVENOAUTH("SYN_EDI_DATA")){
                $rootScope.FORBIDDEN_BY_ALERT('SYN_EDI_DATA'); 
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $scope.isSaving = true;
            Guide.synBranchGuide($scope.guide, onSynSuccess, onSynFailed);
        }

        $scope.hblConfirmChange = function(hblConfirmed) {
            $scope.updateNode('HblConfirmed', 'hblConfirmedTime');         
        }

        $scope.mblBlueNull = function() {
            if($scope.guide && $scope.guide.mblConfirmedTime){
                return false;
            }
            if(!$scope.guide
                || !$scope.guide.guideShipperBl 
                || !$scope.guide.deliveryBl 
                || !$scope.guide.guideNotifyBl 
                || !$scope.guide.pkgsAndType 
                || !$scope.guide.goodsDiscription 
                || !$scope.guide.gwVol
                || !$scope.guide.serviceTerm || !$scope.guide.freightTerm
                || !$scope.guide.polName || !$scope.guide.podName || !$scope.guide.destName){
                return true;
            }
            return false;
        }
        
        $scope.saSentTimeUpdate = function() {
            $scope.guide.saSentTime = new Date();
            $scope.guide.status = "SaSent";
        }
        $scope.vgmTimeUpdate = function() {
            $scope.guide.vgmTime = new Date();
            $scope.guide.status = "vgmSent";
        }
        $scope.deleteGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.delete.question', {
                id: $scope.guide.jobNum
            }), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                Guide.delete({
                    id: $scope.guide.id
                }, function() {
                    layer.close(index);
                    layer.msg($translate.instant('global.delete_success'));
                    goBack();
                });
            }, function() {});
        }
        
        $scope.setVoid = function() {    
            if($rootScope.HAVENOAUTH('AU_VOID_ORDER')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_VOID_ORDER'); 
                return;
            }  
            if ($scope.guide.isVoid) {
                Guide.setVoid({id:$scope.guide.id}, function(result) {
                    loadGuide();                    
                    layer.msg($translate.instant('global.valid_success'));
                }, $rootScope.ALERT_ERROR);
            } else {
                if (!angular.equals(guideCopy, $scope.guide)) {
                    layer.msg($translate.instant('global.saveAlert'));
                    return;
                };
                layer.confirm($translate.instant('autopsApp.guide.delAllRatesAlert'), {
                    icon: 3,
                    title: $translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    Guide.setVoid({id:$scope.guide.id}, function(result) {
                        loadGuide();                    
                        layer.msg($translate.instant('global.invalid_success'));
                    }, $rootScope.ALERT_ERROR);
                }, function() {
                });
            }
        }

        $scope.setIsClosed = function() {
            if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $scope.guide.isClosed = !$scope.guide.isClosed;
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');   
                return;
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                $scope.guide.isClosed = !$scope.guide.isClosed;
                return;
            };
            if (!$scope.guide.isClosed) {
                if ($rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                    $scope.guide.isClosed = !$scope.guide.isClosed;
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN'); 
                    return;
                }
                $scope.guide.isClosed = false;
                $scope.guide.status = "Restored";
            } else {
                $scope.guide.isClosed = true;
                $scope.guide.status = "isClosed";
            }
            Guide.update($scope.guide, function(result) {
                $scope.guide = result;
                guideCopy = angular.copy($scope.guide);
                $scope.$emit('autopsApp:guideUpdate', guideCopy);
                layer.msg($translate.instant('global.save_success'));
                if(result.masterId == 0){
                    layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.msg($translate.instant('global.isSavingMsg'), {
                              shade: 0.3,
                              time: 200000
                            });    
                            Guide.lockSubGuidesAll({
                                masterId: result.id,
                                bValue: result.isClosed,
                                type: "guide"
                            }, function(result) {
                                layer.closeAll();
                                layer.msg($translate.instant('global.save_success'));
                            })
                            layer.close(index);
                        }, function() {
                    });
                }
            }, onSaveError);
        }


        $scope.showDetailedContactInfo = false;
        $scope.showDetailedSet = function(rate) {
            $scope.showDetailedContactInfo = !$scope.showDetailedContactInfo;
        }

        $scope.checkPop = function() {
            var guideCheckStatus = $scope.guide.checkStatus;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/guideRates-dialog.html',
                controller: 'GuideRatesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
            }, function(result) {
                $scope.guide.checkStatus = guideCheckStatus;
            });
        };


        $scope.changeMblNum = function() {
            if (!$scope.guide.etdLocked) {
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.prompt({
                formType: 0,
                value: $scope.guide.mblNum,
                title: $translate.instant('autopsApp.guide.changeMblNum')
            }, function(value, index, elem) {
                layer.close(index);
                value = value.replace(/\s+/g, "");
                Guide.amendMblNumByOp({
                    guideId: $scope.guide.id,
                    mblNum: value
                }, function(result) {
                    loadGuide()                
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(result) {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                });
            });
        }
        $scope.updateVol = function(bookingCtn) {
            if (bookingCtn.length && bookingCtn.width && bookingCtn.height) {
                bookingCtn.width = bookingCtn.width.toFixed(4) - 0;
                bookingCtn.length = bookingCtn.length.toFixed(4) - 0;
                bookingCtn.height = bookingCtn.height.toFixed(4) - 0;
                bookingCtn.vol = bookingCtn.length * bookingCtn.width * bookingCtn.height / 1000000;
                bookingCtn.vol = DataUtils.round(bookingCtn.vol, 5);
                $scope.volChange(bookingCtn);                
            }

        };
        $scope.gwChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.gw){
                bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 4);
                if(!$scope.guide.dontSynTtl){
                    $scope.updateVolGw();
                }                
            }
        };
        $scope.volChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.vol){
                bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                if(!$scope.guide.dontSynTtl){
                    $scope.updateVolGw();
                }   
            }
        };
        $scope.pkgChange = function(bookingCtn) {
            if(bookingCtn.pkgNum){
                if(bookingCtn.vol){
                    bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                }
                if(bookingCtn.gw){
                    bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 4);
                }
                if(!$scope.guide.dontSynTtl){
                    $scope.updateVolGw();
                }   
            }
        };
        
        $scope.updateVolGw = function() {
            DataUtils.updateGuidePl($scope.guide);
            if(!$scope.guide.cwLoked){
                if(!$scope.guide.hblConfirmedTime){
                    $scope.guide.booking.gwVol = null;
                    $scope.getVolumeContent($scope.guide.booking, "gwVol");
                }
                if(!$scope.guide.mblConfirmedTime){
                    $scope.guide.gwVol = null;
                    $scope.getVolumeContent($scope.guide, "gwVol");
                }
            }
        }
        $scope.onCarrierSelected = function(carrier) {
            $scope.guide.carrier = carrier.name;
            $scope.guide.carrierOne = $scope.guide.booking.carrierCode = carrier.code;
            if($scope.guide.porId || $scope.guide.booking.porId){
                if(!$scope.guide.carrierTwo){
                    $scope.guide.carrierTwo = $scope.guide.carrierOne;
                }
            }
            if($scope.guide.deliverytoId || $scope.guide.booking.deliverytoId){
                if(!$scope.guide.carrierThree){
                    $scope.guide.carrierThree = $scope.guide.carrierOne;
                }
            }
            $scope.guide.booking.carrierFullName = carrier.carrierFullName;
            if (!$scope.guide.mblNum) {
                $scope.guide.mblNum = carrier.remarks + "-";
            }
            $rootScope.UPDATE_BY_CARRIER(carrier, 'guide', $scope.guide);
        }

        $scope.onGuideCarrierSelected = function(carrier, fd) {
            $scope.guide[fd] = carrier.code;
        }

        $scope.onOpPalceSelected = function(carrier) {
            $scope.guide.dockCode = carrier.code;
        }

        $scope.deliveryNotice = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.dNoticeSubject');
            email.content = $translate.instant('entity.email.dNoticeContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.ctnLoading = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.warehouseName;
            email.subject = $scope.guide.mblNum + "--" + $translate.instant('entity.email.ctnLoadingSubject');
            email.content = $translate.instant('entity.email.ctnLoadinContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.warehouseVid;
            entity.contactId = $scope.guide.warehouseContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.getDeliveryAddress = function() {
            if (!$scope.guide.booking.remarkTwo) {
                var add = "送货时间: " + DateUtils.convertLocalDateToServer($scope.guide.cargoReadyDate) + "\n\n";
                OpsVender.getDTO({id: $scope.guide.warehouseVid}, function(result){
                    if (result.companyName) {
                        add = add + result.companyName + "\n";
                    }
                    if (result.address) {
                        add = add + result.address + "\n";
                    }
                    if(result.contactList && result.contactList.length > 0){
                        angular.forEach(result.contactList, function(contact){
                            if(contact.id == $scope.guide.warehouseContactId){
                                if (contact.attn) {
                                    add = add + "Pic: " + contact.attn + "\n";
                                }
                                if (contact.mob) {
                                    add = add + "Mob: " + contact.mob + "\n";
                                }
                                if (contact.tel) {
                                    add = add + "Tel: " + contact.tel + "\n";
                                }
                                if (contact.fax) {
                                    add = add + "Fax: " + contact.fax + "\n";
                                }
                                if (contact.email) {
                                    add = add + "Email: " + contact.email + "\n";
                                }
                            }
                        })

                    }
                    $scope.guide.booking.remarkTwo = add;
                })
            }
        }

        $scope.getLoadRemark = function() {
            if (!$scope.guide.booking.loadRemark) {
                var add = "预计货到堆场时间：" + DateUtils.convertLocalDateToServer($scope.guide.cargoReadyDate) + "\n\n";
                add = add + "装箱要求：" + "\n";
                add = add + "报箱号时间：" + "\n";
                add = add + "费用：" + "\n";
                $scope.guide.booking.loadRemark = add;
            }
        }

        $scope.blAttRemark = function(hm) {
            if (hm == "H") {
                $scope.guide.booking.goodsDiscription = $scope.guide.booking.goodsDiscription + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
            } else {
                $scope.guide.goodsDiscription = $scope.guide.goodsDiscription + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
            }
        }

        $rootScope.LOAD_REPORT_TEMPLATES();

        $scope.defaultValueCheck = function(obj, fd, defaultValue) {
            if (!obj[fd]) {
                obj[fd] = defaultValue;
            }
        }

        $scope.unbind = function() {
            layer.confirm($translate.instant('autopsApp.guide.unbindAlert'), {
                title: $translate.instant('global.messageInfo'),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                Guide.unbind({
                    guideId: $scope.guide.id,
                    branchGuideId: $scope.guide.branchGuideId
                }, function(result) {
                    $scope.guide.branchGuideId = null;
                    layer.close(index);
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $scope.hblView = function() {
            var entity = {};
            entity.guide = $scope.guide;
            $uibModal.open({
                templateUrl: 'app/entities/booking/booking-dialog.html',
                controller: 'BookingDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.getHblsCount();
            }, function(result) {
                $scope.getHblsCount();
            });
        };


        $scope.combineHblIntoMbl = function(refreshDes) {
            if(!$scope.hbls){return;}
            Booking.getHbls({refGuideId: $scope.guide.id}, function(result){
                var gw = 0,//件毛体合并
                    pkgs = 0,
                    vol = 0,
                    chargeWeight = 0,
                    isSamePkg = true,
                    pkgType;
                if(refreshDes){
                    $scope.guide.mark = "";
                    $scope.guide.goodsDiscription = "";
                }
                angular.forEach(result, function(data) {
                    if (data.pkgNum) {
                        pkgs = pkgs + data.pkgNum;
                    }
                    if (data.gw) {
                        gw = gw + data.gw;
                    }
                    if (data.vol) {
                        vol = vol + data.vol;
                    }
                    if (data.chargeWeight) {
                        chargeWeight = chargeWeight + data.chargeWeight;
                    }
                    if (!pkgType && data.pkgType) {
                        pkgType = data.pkgType;
                    }
                    if (data.pkgType && pkgType != data.pkgType) {
                        isSamePkg = false;
                    }
                    if(refreshDes && data.mark){
                        $scope.guide.mark += data.mark + "\n";
                    }
                    if(refreshDes && data.goodsDiscription){
                        $scope.guide.goodsDiscription += data.goodsDiscription + "\n";
                    }
                })
                $scope.guide.pkgNum = pkgs;
                if(!$scope.guide.cwLoked){
                    $scope.guide.chargeWeight = chargeWeight;
                    $scope.guide.gw = gw;
                    $scope.guide.vol = vol;
                }
                if (isSamePkg) {
                    $scope.guide.booking.pkgType = pkgType;
                } else {
                    $scope.guide.booking.pkgType = "PKGS";
                }
                $scope.updateGwVol();
                $translate.refresh();
                layer.msg($translate.instant('autopsApp.booking.home.combineHblsQuestion'));
            })
        }

        $scope.factoriesPop = function() {
            $uibModal.open({
                templateUrl: 'app/entities/opsFactory/opsFactory-dialog.html',
                controller: 'OpsFactoryDialogController',
                size: 'md',
                resolve: {
                    entity: function() {
                        return $scope.guide;
                    }
                }
            }).result.then(function(result) {

            }, function() {

            })
        }       
        $scope.amendEtd = function(amendBillDate) {
            if (!$rootScope.IS_OP_CHECK() && $scope.guide.isLocked) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (!$scope.guide.etdLocked && !amendBillDate) {
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };            
            var guideDto = {};
            guideDto.id = $scope.guide.id;
            guideDto.etd = $scope.guide.etd;
            if(amendBillDate && $scope.guide.billDate){
                guideDto.etd = $scope.guide.billDate;
            }
            guideDto.lockEDate = true;
            guideDto.amendBillDate = amendBillDate;
            $uibModal.open({
                templateUrl: 'app/entities/guide/amend-etd.html',
                controller: 'AmendEtdController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guideDto;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
                $translate.refresh();
            }, function() {});
        }

        $scope.splitHBLView = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };            
            var splitGuide = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/guide/split-bl.html',
                controller: 'SplitBlController',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return splitGuide;
                    }]
                }
            }).result.then(function(result) {
                $rootScope.id = "view";    
                $uibModal.open({
                    templateUrl: 'app/entities/guide/air-dialog.html',
                    controller: 'AirDialogController',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return Guide.get({id : result.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                }, function(result) {
                });
            }, function() {});
        }
        KEYBOARD = "exportGuideDialog";
        var saved = false;
        $(document).keydown(function(event) {            
            if( KEYBOARD != "exportGuideDialog"){
                return;
            }
            else if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                if (saved){
                    return;
                }
                else {
                    saved = true;
                    $scope.save();
                }                
            } 
        });
        $scope.$on('modal.closing', function(event, reason, closed){
            if(reason == "escape key press"){
                event.preventDefault(); //You can use this to prevent the modal from closing       
                $scope.goBack();              
            }
        });
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.excelExport = function() {
            var guides = [];
            guides.push($scope.guide);
            var fileName = $translate.instant('autopsApp.guide.packingList') + ".xlsx";           
            OpsFiles.exportPackingList({
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    

        $scope.importPlByExcel = function (file) {
            if (!file) {return;}
            if (!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($scope.guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }    
            var fd = new FormData();
            fd.append('file', file);
            fd.append('guideId', $scope.guide.id);
            $http.post(
                'api/bookingCtnss/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    loadGuide(true);        
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };
        $scope.combineDescription = function() {
            Guide.combineDescription({
                id: $scope.guide.id
            }, function(result) {
                $scope.guide.goodsDiscription = result.key;
            })
        }
        $scope.combineMark = function() {
            Guide.combineMark({
                id: $scope.guide.id
            }, function(result) {
                $scope.guide.mark = result.key;
            })
        }
        $scope.importSi = function(file, mblSi){
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            fd.append('mblSi', mblSi);
            fd.append('id', $scope.guide.id);
            $http.post(
                'api/guides/siImport', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    data = angular.fromJson(data);
                    data = data.result;
                    data.cls = DateUtils.convertDateTimeFromServer(data.cls);
                    data.eta = DateUtils.convertLocalDateFromServer(data.eta);
                    data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                    data.siCutTime = DateUtils.convertDateTimeFromServer(data.siCutTime);
                    data.vgm = DateUtils.convertDateTimeFromServer(data.vgm);
                    data.feederEtd = DateUtils.convertLocalDateFromServer(data.feederEtd);
                    data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                    if (data.booking){
                        data.booking.issueDate = DateUtils.convertLocalDateFromServer(data.booking.issueDate);
                    }
                    angular.forEach(data.bookingCustoms, function(data){
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    })
                    if (data.guideCtns && data.guideCtns.length >0) {
                        for(var i=0; i<data.guideCtns.length; i++){
                            data.guideCtns[i].returnTime = DateUtils.convertDateTimeFromServer(data.guideCtns[i].returnTime);
                        }   
                    }
                    $scope.guide = data;
                }).error(function(error) {
                    if(error.msg){
                        $rootScope.OPS_ALERT(error.msg);
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('global.importFailed'));       
                    }                                        
                });
        };
        $scope.refreshGwVolPkgs = function() {
            if($scope.guide.dontSynTtl){
                return;
            }
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if(!$scope.guide.otherTimeOne){
                layer.msg($translate.instant('autopsApp.booking.wmsNotInAlert'));                  
                return;
            }
            Guide.refreshGwVolPkgs($scope.guide, function(result){
                $scope.guide = result;
                $scope.updateVolGw();
                layer.msg($translate.instant('autopsApp.guide.synSuccess'));  
            })
        }

        $scope.etdChange = function() {
            $scope.guide.booking.issueDate = $scope.guide.etd;
        }

        $scope.copyHblProp = function(fd) {
            $scope.guide[fd] = $scope.guide.booking[fd];
        }

        $scope.copyMblProp = function(fd) {
            $scope.guide.booking[fd] = $scope.guide[fd];
        }

        $scope.ratesPop = function() {      
             if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };    
            $rootScope.GUIDE_RATES_POP($scope.guide); 
        } 

        $scope.setActiveTab = function (index) {
            if(index == 1){
                $scope.activeTab = activeTabCopy;
            }
            else {
                activeTabCopy = index;
            }            
        }

        $scope.isInvalid = function () {
            if($rootScope.account.companyId == 152 || $rootScope.account.companyId == 153 || $rootScope.account.companyId == 162){
                return false;
            }
            if(!$scope.guide 
                || !$scope.guide.submited
                || $scope.guide.isVoid){
                return true;
            }
            if($rootScope.account.mustAudit && $scope.guide.checkStatus != 'approved'){
                return true;
            }
            return false;
        }

        $scope.loadByJobNum = function() {
            $scope.isSaving = true;
            Guide.getGuideByJobNumAndType({
                companyId: $scope.guide.companyId,
                jobNum: $scope.jobNumCopy,
                type: $scope.guide.shipmentType,
                isExport: $scope.guide.isExport
            }, function(result) {
                $scope.guide = result;
                initGuide();
                $scope.isSaving = false;
                layer.msg($translate.instant('entity.action.loadSuccess'));
                $scope.files = [];
                $scope.clientFiles = [];
            }, function(result){
                layer.msg($translate.instant('global.loadFailed'));
            })
        }

        $scope.awbChargesDialog = function(obj) {      
            var entity = {};
            entity.amt = obj.awbWeight;
            entity.str = obj.awbCharges;
            entity.guide = angular.copy($scope.guide);
            if(obj.booking && obj.awbCharges == guideCopy.awbCharges){
                entity.oldAmt = guideCopy.awbWeight;
            }
            else if(!obj.booking && obj.awbCharges == guideCopy.booking.awbCharges){
                entity.oldAmt = guideCopy.booking.awbWeight;
            }            
            $uibModal.open({
                templateUrl: 'app/entities/guide/awb-charges-dialog.html',
                controller: 'AwbChargesDialogController',
                size: 'md',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                obj.awbCharges = result.chargesTxt;                
                if(obj.booking){
                    obj.awbChargesTtl = result.ttlAmt;
                    $scope.updateChargesTtl("MAWB");
                }      
                else {
                    obj.otherAgentFee = result.ttlAmt;
                    $scope.updateChargesTtl("HAWB");
                }
            }, function() {
            });    
        } 

        $scope.wmswarehouses = null;
        $scope.getWarehouses = function() {
            if ($scope.wmswarehouses){
                return;
            }
            if ($scope.guide.isVirtual 
                        && (!$scope.guide.medCompanyId || $scope.guide.medCompanyId == $rootScope.account.companyId)){
                WmsWarehouse.getAll({
                    includeOwn: false,
                    includeVirtual: true,
                    includeGroup: false,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                    $scope.wmswarehouses = data;
                });
            }
            else {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                    $scope.wmswarehouses = data;
                });
            }
        }

        $scope.wmsWarehouseSelected = function(warehouse) {
            // if($scope.guide.jobNum && warehouse.companyId && $scope.guide.companyId != warehouse.companyId){
            //     $scope.guide.whName = guideCopy.whName;
            //     layer.msg($translate.instant('autopsApp.guide.warehouseInvalidAlert'));
            //     return;
            // }            
            var noWmsCargo = true;
            angular.forEach($scope.guide.booking.bookingCtns, function(cargo){
                if(cargo.wmsCargoId){
                    noWmsCargo = false;
                }
            })
            if(!noWmsCargo){
                $scope.guide.whName = guideCopy.whName  ;
                layer.msg($translate.instant('autopsApp.guide.clearWmsCargoAlert'));
                return;
            }      
            $scope.guide.whName = warehouse.name;
            $scope.guide.whId = warehouse.id;

            // if(warehouse.companyId){
            //     $scope.guide.companyId = warehouse.companyId;
            //     $scope.guide.medCompanyId = warehouse.medCompanyId;
            //     $scope.guide.isVirtual = warehouse.isVirtual;
            //     $scope.guide.medCompany = warehouse.medCompany;
            //     $scope.guide.medVenderId = warehouse.medVenderId;
            // }
            if($scope.guide.deliveryChannel){
                Guide.checkChannel($scope.guide, function(){
                }, function(){
                    layer.confirm($translate.instant('global.invalidChannelAlert'), {
                        title: "Msg",
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                    }, function(index) {
                        layer.close(index);
                        $scope.guide.deliveryChannel = null;
                        $translate.refresh();
                    }, function() {
                        return;
                    });
                })
            }
            
        }

        $scope.getCargo = function() {
            if($scope.guide.masterId == 0){                
                layer.msg($translate.instant('autopsApp.guide.masterGetCargoAlert'));
                return;
            }
            if (!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var entt = {};
            entt.guide = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-dialog.html',
                controller: 'ExpressCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                loadGuide(true);                
            }, function() {
            });
        }
        $scope.printIn = function(reportType){
            // if($rootScope.account.authorities.indexOf('WMS') === -1){
            //     layer.msg($translate.instant('global.forbiddenWMS'));
            //     return;
            // }
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };  
            var wmsIn = {isOut:false};
            wmsIn.guideId = $scope.guide.id;

            OpsFiles.generateWmsInPaper({reportType: reportType},wmsIn,function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }
        $scope.wmsLabels = function(reportType) {
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if($scope.guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            var wmsIn = {};
            wmsIn.guideId = $scope.guide.id;
            wmsIn.companyId = $scope.guide.companyId;
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.saveTempRegistIn = function() {
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if(!$scope.guide.customerId || !$scope.guide.whId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseClientAndWarehouseTips'));                
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-register.html',
                controller: 'WmsInRegisterController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WmsIn', function(WmsIn) {
                        return WmsIn.getWmsInByGuide({isOut: false}, angular.copy($scope.guide)).$promise;
                    }]
                }
            }).result.then(function() {
                loadGuide();
            }, function() {
                loadGuide();
            });
        }
        
        $scope.antiTempRegistIn = function(bCtn) {
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if($rootScope.HAVENOAUTH('AU_WMS_ANTI')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_ANTI'); 
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var wmsGuide;
            if(bCtn){
                wmsGuide = {};
                wmsGuide.id = $scope.guide.id;
                wmsGuide.booking = {};
                wmsGuide.booking.bookingCtns = [];
                wmsGuide.booking.bookingCtns.push(bCtn);
            }
            else {
                wmsGuide = angular.copy($scope.guide);
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.antiInAlert'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    WmsIn.antiTempRegistIn(wmsGuide, function(){
                        layer.msg($translate.instant('global.messages.submitted'));
                        loadGuide();                   
                    }, function(result){
                        loadGuide(); 
                        if(result.status == 403){
                            $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.cargoIsOutAlert'));
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }                        
                    });                  
                }, function() {
            });  
        }
        
        $scope.plConfirmedChange = function() {
            if($rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP') && $scope.guide.plConfirmTime){
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP'); 
                $scope.guide.plConfirmed  = true;
                return;            
            }
            if(!$scope.guide.otherTimeTwo && !$scope.guide.otherTimeOne){
                layer.msg($translate.instant('autopsApp.booking.wmsNotInAlert'));
                return;
            }
            if ($scope.guide.plConfirmed) {
                $scope.guide.plConfirmTime = new Date();
                // if($rootScope.HAVEAUTH('WMS')){
                //     $scope.refreshGwVolPkgs();
                // }
                $scope.guide.status = "plConfirmed";
            }
            else {
                $scope.guide.plConfirmTime = null;
                $scope.guide.status = guideCopy.status;
            }
        }
        
        $scope.synEtdToHbls = function() {
            if(!$scope.guide.id){
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('autopsApp.guide.synEtdToHbls') + "?", {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    Guide.synEtdToHbls($scope.guide, function(){
                        layer.msg($translate.instant('global.syn_success'));             
                    }, function(result){
                        layer.msg($translate.instant('global.syn_fail'));                      
                    });                  
                }, function() {
            });  
        }
        $scope.previewReport = function(code, truckId) {
            if($rootScope.VIEW_GUIDE_FORBIDDEN(code)){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }
        $scope.exportExcelPdf = function(format, code, fileName, idCopy) {
            if($rootScope.VIEW_GUIDE_FORBIDDEN(code)){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, fileName);
            });
        }

        // $scope.getTrucksAndBroker = function() {
        //     if(!$scope.guide.booking.id){
        //         return;
        //     }
        //     Booking.getTrucksAndBroker({id: $scope.guide.booking.id}, function(result){
        //         $scope.bookingTrucks = result.bookingTrucks;
        //     })
        // }
        // var unsubscribeTruck = $rootScope.$on('autopsApp:bookingTruckUpdate', function(event, result) {
        //     if($scope.bookingTrucks && $scope.bookingTrucks.length > 0){
        //         angular.forEach($scope.bookingTrucks, function(data){
        //             if(data.id == result.id){
        //                 angular.copy(result, data);
        //             }
        //         })
        //     }
        //     else {
        //         $scope.bookingTrucks = [];
        //         $scope.bookingTrucks.push(result);
        //     }
        // });
        // $scope.$on('$destroy', unsubscribeTruck);

        $scope.updateNode = function(status, fd) {
            if(!$rootScope.IS_OP_CHECK()){
                layer.msg($translate.instant('autopsApp.guide.opDocEnterAlert'));                
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(status == "status"){
                if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');   
                    return;
                }
                if (!$scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE'); 
                    return;
                }
            }
            if($scope.guide[fd] || $scope.guide.booking[fd]){
                if($rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP') && status != 'Cancelled'){
                    $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP'); 
                    return;            
                }
                layer.confirm($translate.instant('autopsApp.guide.clearNodeAlert'), {icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        Guide.setStatusOne({
                            guideId: $scope.guide.id,
                            status: status
                        }, function(result) {
                            layer.msg($translate.instant('global.save_success'));
                            loadGuide();    
                        }, function(result) {
                            layer.closeAll();
                            $scope.isSaving = false;
                            $rootScope.ALERT_ERROR(result);                        
                        });
                        layer.close(index);
                    }, function() {
                });
                return;
            }
            var entity = {};
            entity.guide = angular.copy($scope.guide);
            entity.status = status;
            $uibModal.open({
                templateUrl: 'app/entities/ops-track-status/ops-track-status-detail.html',
                controller: 'OpsTrackStatusDetailController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                layer.msg($translate.instant('global.save_success'));
                loadGuide();         
            }, function() {
            });
        }

        $scope.importHGuides = function(file) {
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('guideId', $scope.guide.id);
            fd.append('isExport', true);
            fd.append('file', file);
            $http.post(
                'api/guides/importExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                loadGuide();
                layer.msg($translate.instant('global.importSuccess'));
            }).error(function(error) {
                if (error.msg) {
                    $rootScope.OPS_ALERT(error.msg);
                } else {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));       
                }

            });
        };
        $scope.deliveryWayChange = function() {
            // if($scope.guide.deliveryWay == "express" && $scope.guide.shipmentType != "LOCAL" && $scope.guide.shipmentType != "WMS"){
            //     $scope.carriers = $rootScope.OPSPORTS.expressLiners;
            // }
        };
        $scope.customsPop = function() {
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.customsMgmt($scope.guide);
        };
        
        var unsubscribeGuide = $rootScope.$on('autopsApp:loadGuide', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }   
        });
        
        $scope.$on('$destroy', unsubscribeGuide);
        $scope.deletePlByGuideId = function() {
            var loadIndex = layer.load(1, {shade: 0.3});
            BookingCtns.deletePlByGuideId({
                guideId: $scope.guide.id
            }, function(){
                layer.close(loadIndex);  
                loadGuide();
            }, function(result){
                layer.close(loadIndex);  
                if(result.status == 409){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});                        
                }
                else {
                    layer.msg($translate.instant('global.delete_fail'));
                }
            });
        };
        $scope.countWeight = function(type) {
            if(type == 'MAWB'){
                if($scope.guide.priceAsArranged){
                    return;
                }
                if($scope.guide.awbWeight && $scope.guide.rateCharge){
                    $scope.guide.awbPrice = ($scope.guide.awbWeight * $scope.guide.rateCharge).toFixed(2) - 0;
                }
                else {
                    $scope.guide.awbPrice = null;
                }
            }
            else if(type == 'HAWB'){
                if($scope.guide.booking.priceAsArranged){
                    return;
                }
                if($scope.guide.booking.awbWeight && $scope.guide.booking.rateCharge){
                    $scope.guide.booking.awbPrice = ($scope.guide.booking.awbWeight * $scope.guide.booking.rateCharge).toFixed(2) - 0;
                }else{
                    $scope.guide.booking.awbPrice = null;
                }
            }
            $scope.updateChargesTtl(type);
        }

        $scope.updateChargesTtl = function(type) {
            if(type == 'MAWB'){
                $scope.guide.ttlPpd = 0;
                $scope.guide.ttlCct = 0;
                if($scope.guide.valuaChrg){
                    if(!$scope.guide.booking.valuaChrgCct){
                        $scope.guide.ttlPpd += $scope.guide.valuaChrg;
                    }
                    else{
                        $scope.guide.ttlCct += $scope.guide.valuaChrg;
                    }
                }
                if($scope.guide.taxChrg){
                    if(!$scope.guide.booking.taxChrgCct){
                        $scope.guide.ttlPpd += $scope.guide.taxChrg;
                    }
                    else{
                        $scope.guide.ttlCct += $scope.guide.taxChrg;
                    }
                }
                if($scope.guide.otherAgentFee){
                    if(!$scope.guide.booking.otherAgentFeeCct){
                        $scope.guide.ttlPpd += $scope.guide.otherAgentFee;
                    }
                    else{
                        $scope.guide.ttlCct += $scope.guide.otherAgentFee;
                    }
                }
                if($scope.guide.priceAsArranged){
                    if($scope.guide.freightTerm == 'PPD'){
                        $scope.guide.ttlPpd = 'AS ARRANGED';
                    }
                    if($scope.guide.freightTerm == 'CCT'){
                        $scope.guide.ttlCct = 'AS ARRANGED';
                    }
                }
                else if($scope.guide.awbPrice){
                    if($scope.guide.freightTerm == 'PPD'){
                        $scope.guide.ttlPpd += $scope.guide.awbPrice;
                    }
                    else if($scope.guide.freightTerm == 'CCT'){
                        $scope.guide.ttlCct += $scope.guide.awbPrice;
                    }
                }

                if($scope.guide.chargesAsArranged){
                    if($scope.guide.otherVal == 'PPD'){
                        $scope.guide.ttlPpd = 'AS ARRANGED';
                    }
                    if($scope.guide.otherVal == 'CCT'){
                        $scope.guide.ttlCct = 'AS ARRANGED';
                    }
                }
                else if($scope.guide.awbChargesTtl){
                    if($scope.guide.otherVal == 'PPD' && !isNaN($scope.guide.ttlPpd)){
                        $scope.guide.ttlPpd += $scope.guide.awbChargesTtl;
                    }
                    else if($scope.guide.otherVal == 'CCT' && !isNaN($scope.guide.ttlCct)){
                        $scope.guide.ttlCct += $scope.guide.awbChargesTtl;
                    }
                } 
                if(!isNaN($scope.guide.ttlPpd)){
                    $scope.guide.ttlPpd = $scope.guide.ttlPpd.toFixed(2);
                }
                if(!isNaN($scope.guide.ttlCct)){
                    $scope.guide.ttlCct = $scope.guide.ttlCct.toFixed(2);
                }
            }
            else {
                $scope.guide.booking.ttlPpd = 0;
                $scope.guide.booking.ttlCct = 0;

                if($scope.guide.booking.valuaChrg){
                    if(!$scope.guide.booking.valuaChrgCct){
                        $scope.guide.booking.ttlPpd += $scope.guide.booking.valuaChrg;
                    }
                    else{
                        $scope.guide.booking.ttlCct += $scope.guide.booking.valuaChrg;
                    }
                }
                if($scope.guide.booking.taxChrg){
                    if(!$scope.guide.booking.taxChrgCct){
                        $scope.guide.booking.ttlPpd += $scope.guide.booking.taxChrg;
                    }
                    else{
                        $scope.guide.booking.ttlCct += $scope.guide.booking.taxChrg;
                    }
                }
                if($scope.guide.booking.awbChargesTtl){
                    if(!$scope.guide.booking.otherAgentFeeCct){
                        $scope.guide.booking.ttlPpd += $scope.guide.booking.awbChargesTtl;
                    }
                    else{
                        $scope.guide.booking.ttlCct += $scope.guide.booking.awbChargesTtl;
                    }
                }                 
                if($scope.guide.booking.priceAsArranged){
                    if($scope.guide.booking.freightTerm == 'PPD'){
                        $scope.guide.booking.ttlPpd = 'AS ARRANGED';
                    }
                    if($scope.guide.booking.freightTerm == 'CCT'){
                        $scope.guide.booking.ttlCct = 'AS ARRANGED';
                    }
                }
                else if($scope.guide.booking.awbPrice){
                    if($scope.guide.booking.freightTerm == 'PPD'){
                        $scope.guide.booking.ttlPpd += $scope.guide.booking.awbPrice;
                    }
                    else if($scope.guide.booking.freightTerm == 'CCT'){
                        $scope.guide.booking.ttlCct += $scope.guide.booking.awbPrice;
                    }
                }
                if($scope.guide.booking.chargesAsArranged){
                    if($scope.guide.booking.otherVal == 'PPD'){
                        $scope.guide.booking.ttlPpd = 'AS ARRANGED';
                    }
                    if($scope.guide.booking.otherVal == 'CCT'){
                        $scope.guide.booking.ttlCct = 'AS ARRANGED';
                    }
                }
                else if($scope.guide.booking.otherAgentFee){
                    if($scope.guide.booking.otherVal == 'PPD' && !isNaN($scope.guide.booking.ttlPpd)){
                        $scope.guide.booking.ttlPpd += $scope.guide.booking.otherAgentFee;
                    }
                    else if($scope.guide.booking.otherVal == 'CCT' && !isNaN($scope.guide.booking.ttlCct)){
                        $scope.guide.booking.ttlCct += $scope.guide.booking.otherAgentFee;
                    }
                }
                if(!isNaN($scope.guide.booking.ttlPpd)){
                    $scope.guide.booking.ttlPpd = $scope.guide.booking.ttlPpd.toFixed(2);
                }
                if(!isNaN($scope.guide.booking.ttlCct)){
                    $scope.guide.booking.ttlCct = $scope.guide.booking.ttlCct.toFixed(2);
                }                
            }
            $translate.refresh();
        }

        $scope.getVolumeContent = function(obj, fd){
            if(obj[fd]){
                return;
            }
            var ctnsArr=[];
            var ttlvol = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(content) {
                if(content.length && content.width && content.height && content.pkgNum){
                    ttlvol += content.length * content.width * content.height * content.pkgNum;
                    var vol = content.length + ' X ' + content.width + ' X ' + content.height;
                    if(ctnsArr.indexOf(vol) < 0){
                        ctnsArr.push(vol);
                    }
                }
            })
            var CtnsObj={};
            angular.forEach(ctnsArr, function(val) {
                var num = 0;
                angular.forEach($scope.guide.booking.bookingCtns, function(content) {
                    if(content.length && content.width && content.height && content.pkgNum){
                        var vol = content.length + ' X ' + content.width + ' X ' + content.height;
                        if(val == vol){
                            if(content.pkgNum){
                                num+= Number(content.pkgNum);
                            }else{
                                num++;
                            }                            
                        }
                    }
                })
                CtnsObj[val] = {
                    'num':num
                };
            })
            var volumeStr="";
            for(var k in CtnsObj){
                volumeStr += k + 'CM/' + CtnsObj[k].num + '\n';
            }
            if(volumeStr.length == 0){
                if($scope.guide.vol){
                    volumeStr+='VOL: '+ $scope.guide.vol.toFixed(4) +" CBM";
                }    
                else if ($scope.guide.booking.vol){
                    $scope.guide.gwVol = 'VOL: '+ $scope.guide.booking.vol.toFixed(4) +" CBM";
                }
            }
            else {
                volumeStr+='VOL: '+ (ttlvol/1000000).toFixed(4) +" CBM";
            }            
            obj[fd] = volumeStr;
        }
        $scope.setAutoTracking = function() {
            if (!$scope.guide.id || !angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.AUTO_TRACKING($scope.guide);
        }
        $scope.getCustomExcelImportRecord = function() {
            if ($scope.customExcelImportRecords) {
                return;
            }
            GridState.getCustomExcelImportRecord({types: Array.from(arguments)}, function(result) {
                $scope.customExcelImportRecords = result;
            })
        }

        $scope.importAirOrdersByCustomize = function(customExcelImportRecord, file) {
            if ($scope.guide.id && !angular.equals($scope.guide, guideCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.importOrdersByCustomize(customExcelImportRecord, file, $scope.guide)
        }
        var unsubscribeUpdateByChannel = $rootScope.$on('autopsApp:guideUpdateByChannel', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }   
        });
        $scope.$on('$destroy', unsubscribeUpdateByChannel);
        
        $scope.fmsRapidIn = function() {
            if (!$scope.guide.id || !angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.FMS_RAPID_IN($scope.guide.shipmentType, $scope.guide.id);
        }      

        $scope.synOrderBranchGuide = function(synOrderConfig) {            
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.SYN_ORDER_DO(synOrderConfig, $scope.guide);
        }

          
    }
})();
