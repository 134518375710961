(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ImportDialogController', ImportDialogController);

    ImportDialogController.$inject = ['$timeout', '$stateParams','$scope', '$rootScope', '$http', '$state', '$location', '$uibModal', '$translate', '$uibModalInstance', 'Principal', 'Guide',
        'Booking', 'DateUtils', 'BookingCtns', 'entity', 'WmsIn', 'WmsWarehouse', 'GuideFclCtn',
        'OpsVender', 'GuideCtns', 'OpsCompany', 'OpsReport', 'OpsCarrier',
        'OpsFiles', 'WinAES', 'DataUtils', 'OpsCost', 'Email', 'GuideTemplate', 'GridState'
    ];

    function ImportDialogController($timeout, $stateParams, $scope, $rootScope, $http, $state, $location, $uibModal, $translate, $uibModalInstance, Principal, Guide,
        Booking, DateUtils,  BookingCtns, entity, WmsIn, WmsWarehouse, GuideFclCtn, 
        OpsVender, GuideCtns, OpsCompany, OpsReport, OpsCarrier,
        OpsFiles, WinAES, DataUtils, OpsCost, Email, GuideTemplate, GridState) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                initGuide();
            });
        };

        $scope.guide = entity;
        var activeTabCopy = $rootScope.GUIDEACTIVETAB == null?0:$rootScope.GUIDEACTIVETAB;
        $scope.activeTab = activeTabCopy;

        var guideCopy = {};
        var path = $location.path();
        $timeout(function() {
            if (path.indexOf("dialog") >= 0) {
                $rootScope.id = "view";
            }
            loadData();            
            $translate.refresh();    
        });
        $scope.jobNumCopy = null;
        $scope.cargoIsOut = false;
        $scope.cargoIsIn = false;
        var initGuide = function() {
            $scope.jobNumCopy = $scope.guide.jobNum;
            if($scope.guide.otherTimeTwo){
                $scope.cargoIsOut = true;
            }
            else {
                $scope.cargoIsOut = false;
            }
            if($scope.guide.otherTimeOne){
                $scope.cargoIsIn = true;
            }
            else {
                $scope.cargoIsIn = false;
            }
            if ($scope.guide.id 
                && $rootScope.account.mustAudit 
                && $scope.guide.createdBy != $rootScope.account.id
                && isOpDocOnly() 
                && $scope.guide.checkStatus != 'approved') {
                layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                goBack();
                return;
            }
            if ($scope.guide.guideCtns && $scope.guide.booking && $scope.guide.guideFclCtns && $scope.guide.shipmentType == "FCL") {
                for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                    if ($scope.guide.guideFclCtns[i].ctnType) {
                        var size = 0;
                        for (var ii = 0; ii < $scope.guide.guideCtns.length; ii++) {
                            if ($scope.guide.guideCtns[ii].ctnType == $scope.guide.guideFclCtns[i].ctnType) {
                                size++;
                            }
                        }
                        for (var j = 0; j < $scope.guide.guideFclCtns[i].num - size; j++) {
                            $scope.guide.guideCtns.push({
                                ctnType: $scope.guide.guideFclCtns[i].ctnType
                            });
                        }
                    }
                }
            }            
            if (!$scope.guide.guideShipperBl) {
                $scope.guide.guideShipperBl = $rootScope.account.blShow;
            }

            $scope.lanes = $rootScope.GET_LINES($scope.guide.shipmentType);
        
            if (!$scope.guide.polName){
                angular.forEach($rootScope.ISSEA($scope.guide.shipmentType)?$rootScope.OPSPORTS.seaports:$rootScope.OPSPORTS.airports, function(data){
                    if (data.name == $rootScope.account.defaultPol){
                        $scope.guide.polName = data.name;
                        $scope.guide.polId = data.code;
                        $scope.guide.booking.polName = data.name;
                        $scope.guide.booking.polId = data.code;
                    }
                })
            }
            if (!$scope.guide.issuePlace){
                $scope.guide.issuePlace = $rootScope.account.defaultPol;
            }
            if (!$scope.guide.booking) {
                $scope.guide.booking = {};
            }
            if (!$scope.guide.booking.notifyBl) {
                $scope.guide.booking.notifyBl = "SAME AS CONSIGNEE";
            }
            if (!$scope.guide.guideNotifyBl) {
                $scope.guide.guideNotifyBl = "SAME AS CONSIGNEE";
            }

            if ((!$scope.guide.guideFclCtns || $scope.guide.guideFclCtns.length == 0) && $scope.guide.shipmentType == "FCL") {
                $scope.guide.guideFclCtns = [];
                $scope.guide.guideFclCtns.push({});
            }

            if (!$scope.guide.guideDocs) {
                $scope.guide.guideDocs = [];
            }
            if (!$scope.guide.guideCtns) {
                $scope.guide.guideCtns = [];
            }
            /*初始化创建人*/
            if (!$scope.guide.createdBy || !$scope.guide.companyId) {
                $scope.guide.createdBy = $rootScope.account.id;                
                $scope.guide.companyId = $rootScope.account.companyId;
            }            
            if (!$scope.guide.id && !$scope.guide.salesId && $scope.salesFilter($rootScope.account)){
                $scope.guide.salesId = $rootScope.account.id;
                $scope.guide.salesName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.salesId = $rootScope.account.id;
                $scope.guide.team = $rootScope.account.team;
                $scope.guide.branch = $rootScope.account.branch;
            }
            if (!$scope.guide.id && !$scope.guide.opId && $scope.opFilter($rootScope.account)){
                $scope.guide.opName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.opId = $rootScope.account.id;
                $scope.guide.opId = $rootScope.account.id;
            }
            if (!$scope.guide.id && !$scope.guide.cusId && $scope.cusFilter($rootScope.account)){
                $scope.guide.cusId = $rootScope.account.id;
                $scope.guide.cusName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                $scope.guide.booking.cusId = $rootScope.account.id;
            }
            if (!$scope.guide.id && !$scope.guide.checkById && $scope.marketFilter($rootScope.account)){
                $scope.guide.checkById = $rootScope.account.id;
                $scope.guide.checkByName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            if ($scope.guide.status == "new" && $scope.guide.booking.id) {
                OpsFiles.viewBookinSendedFile({
                    guideId: $scope.guide.id
                }, function(response) {
                    var file = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    if (file.size == 0) {
                        return;
                    }
                    var fileURL = URL.createObjectURL(file);
                    var title = $translate.instant('autopsApp.guide.detail.booking');
                    layer.open({
                        type: 2,
                        area: ['900px', '680px'],
                        maxmin: true,
                        title: title,
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        btn: [$translate.instant('autopsApp.guide.layer.acceptBooking'), $translate.instant('entity.action.back'), $translate.instant('autopsApp.guide.layer.declineBooking')],
                        content: fileURL,
                        yes: function(index, layero) {
                            $scope.guide.booking.status = "Confirmed";
                            $scope.guide.status = "Uncommitted";
                            $scope.guide.booking.lastModifiedTime = new Date();
                            Guide.update($scope.guide, onSaveSuccess, onSaveError);
                            $translate.refresh();
                        },
                        cancel: function(index) {
                            layer.close(index);
                            goBack();
                        },
                        btn3: function(index, layero) {
                            $scope.guide.booking.status = "Declined";
                            $scope.guide.status = "Declined";
                            $scope.guide.booking.lastModifiedTime = new Date();
                            Guide.update($scope.guide, onSaveSuccess, onSaveError);
                            goBack();
                        }
                    });
                }, function(error) {
                    //layer.msg($translate.instant('autopsApp.guide.layer.noBooking'));
                });
            };
            guideCopy = angular.copy($scope.guide);
            if (guideCopy.jobNum){
                document.title = guideCopy.jobNum; 
            }      
            else {
                document.title = "New Import Biz"; 
            }
            $rootScope.VOID_MARK($scope.guide);
            $rootScope.AUTOFULLNODE($scope.guide);
            if($rootScope.GUIDEACTIVETAB == 2 || $rootScope.GUIDEACTIVETAB == 3){
                $scope.getHblsCount();
            }
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
        }
        $scope.loadGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.refreshAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {
            });
        }

        var loadGuide = function(refreshPWV) {
            Guide.get({
                id: $scope.guide.id
            }, function(result) {
                $scope.guide = result;
                initGuide();
                if(refreshPWV){
                    $scope.updateVolGw();
                    $scope.save();
                }
            });
        }

        var hblsAmtCopy = null;
        var oldGuideId = null;

        var loadData = function() {
            if ($rootScope.id == "view") {
                initGuide();
            } else if ($rootScope.id == "copy") {
                hblsAmtCopy = $scope.guide.expressLabelId;
                $scope.guide.expressLabelId = null;
                oldGuideId = $scope.guide.id;

                $scope.guide = DataUtils.clearCopyGuide($scope.guide);
                var now = new Date();                
                $scope.guide.createdTime = now;
                $scope.guide.lastModifiedTime = now;
                $scope.guide.createdBy = $rootScope.account.id;
                $scope.guide.companyId = $rootScope.account.companyId;
                $scope.guide.checkStatus = "notChecked";
                
                if ($rootScope.IS_OP_CHECK()) {
                    $scope.guide.status = "new";
                } else {
                    $scope.guide.status = "Uncommitted";
                }
                $rootScope.COPY_GUIDE_CHOOSE($scope.guide);
                initGuide();
            } else {
                var now = new Date();
                if (angular.isDefined($rootScope.account)) {
                    $scope.guide = {
                        createdBy: $rootScope.account.id,
                        companyId: $rootScope.account.companyId,
                        createdTime: now,
                        shipmentType: $rootScope.newShipmentType,
                        booking: {
                            divVolBy: $rootScope.newShipmentType == "AIR"?6000:null,
                            shipmentType: $rootScope.newShipmentType,
                            bookingCtns: [],
                            bookingPrices: [],
                            bookingTrucks: []
                        },
                        bookingCustoms: [],
                        guideRates: [],
                        guideDocs: [],
                        guideCtns: [],
                        bizType: "nomination",
                        checkStatus: "notChecked",
                        chargeByCbm: $rootScope.newShipmentType != "AIR",
                        isApplied: false,
                        isExport: false,
                        allBilled: false,
                        isVoid: false,
                        isClosed: false,
                        isWood: false,
                        isTemplate: false,
                        isLocked: false,
                        issueFeederHbl: false,
                        etdLocked: false,
                        hidenCost: false,
                        hidenSelling: false,
                        vat: false,
                        incomeTax: false,
                        lastModifiedTime: now
                    };
                    if ($rootScope.IS_OP_CHECK()) {
                        $scope.guide.status = "new";
                    } else {
                        $scope.guide.status = "Uncommitted";
                    }
                    initGuide();
                }
            }
        }

        var isOpDocOnly = function() {
            if(path.indexOf("dialog") >= 0){
                if ($rootScope.account.id == $scope.guide.salesId || $rootScope.account.id == $scope.guide.cusId) {
                    return false;
                }                
                if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                    return true;
                }
                var authorities = ["ROLE_BOSS", "ROLE_SALES", "ROLE_CS", "ROLE_MARKET"];
                for (var i = 0; i < authorities.length; i++) {
                    if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                        return false;
                    }
                }
                return true;
            }
            if($rootScope.choosedRole == "ROLE_OPERATOR" || $rootScope.choosedRole == "ROLE_DOCUMENT" || ($rootScope.choosedRole && $rootScope.choosedRole.indexOf("TABLE") !== -1)){
                return true;
            }
            return false;           
        };


        var isOpOrSales = function() {
            var authorities = ["ROLE_BOSS", "ROLE_DOCUMENT", "ROLE_MARKET", "ROLE_OPERATOR", "ROLE_SALES", "ROLE_CS"];   
            if (!$rootScope.account || angular.equals($rootScope.account, {})){
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.payerData = {
            value: []
        };

        var payerChooseOneForEmailIndex = 0;

        $scope.opFilter = function(item) {
            return item.authorities.indexOf("ROLE_OPERATOR") !== -1;
        };
        $scope.docFilter = function(item) {
            return item.authorities.indexOf("ROLE_DOCUMENT") !== -1;
        };
        $scope.updateDoc = function(item) {
            $scope.guide.docName = item.name;
            $scope.guide.booking.docId = item.id;
            $scope.guide.docId = item.id;
        };
        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.cusFilter = function(item) {
            return item.authorities.indexOf("ROLE_CS") !== -1;
        };
        $scope.marketFilter = function(item) {
            return item.authorities.indexOf("ROLE_MARKET") !== -1;
        };
        $scope.finFilter = function(item) {
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };
        $scope.truckByFilter = function(item) {
            return item.authorities.indexOf("TRUCK_BY") !== -1;
        };
        $scope.customByFilter = function(item) {
            return item.authorities.indexOf("CUSTOM_BY") !== -1;
        };
        $scope.hidenAgent = function() {    
            if ($scope.guide && !$scope.guide.deliverySecret) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})){
                return true;
            }
            var authorities = ["ROLE_OPERATOR", "ROLE_ACCOUNT", "ROLE_DOCUMENT", "ROLE_BOSS"];
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) != -1) {
                    return false;
                }
            }
            return true;
        };

        $scope.hidenSelling = function() {    
            if ($scope.guide && !$scope.guide.hidenSelling){
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})){
                return true;
            }            
            return $rootScope.account.authorities.indexOf("FUN_VIEWSELLING") == -1;
        };

        $scope.hideCost = function() {    
            if ($scope.guide && !$scope.guide.hidenCost){
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})){
                return true;
            }            
            return $rootScope.account.authorities.indexOf("FUN_VIEWCOST") == -1;
        };

        $scope.files = new Array();
        $scope.clientFiles = new Array();
        var goBack = function() {
            $uibModalInstance.close(guideCopy);
        }

        $scope.goBack = function() {
            if (!angular.equals($scope.guide, guideCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {                    
                    layer.close(index);
                    goBack();
                }, function() {});
                return;
            } else {
                goBack();
            }
        }

        $scope.deleteCtn = function(bookingCtn) {
            if(!bookingCtn.id || !$scope.guide.id){
                var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.booking.bookingCtns.splice(index, 1);
                };
                return;
            }
            if($scope.guide.isClosed){return;}
            if (!angular.equals(guideCopy, $scope.guide) && $scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var loadIndex = layer.load(1, {shade: 0.3});
            BookingCtns.delete({
                id: bookingCtn.id
            }, function(){
                layer.close(loadIndex);  
                loadGuide();
            }, function(result){
                layer.close(loadIndex);  
                if(result.status == 409){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});                        
                }
                else {
                    layer.msg($translate.instant('global.delete_fail'));
                }
            });
        };
        $scope._selected = function($item, which) {
            switch (which) {
                case "gporChoose":
                    $scope.guide.porId = $item.code;
                    break;
                case "gpolChoose":
                    $scope.guide.polId = $item.code;
                    $scope.guide.polRegion = $item.region;
                    break;
                case "gpodChoose":
                    $scope.guide.podId = $item.code;
                    if(!$scope.guide.destId || !$scope.guide.id){
                        $scope.guide.destId = $item.code;
                        $scope.guide.destName = $item.name;
                        $scope.guide.podRegion = $item.region;
                        $scope.guide.destCountryCode = $item.countryCode;
                    }
                    if(!$scope.guide.podRegion){
                        $scope.guide.podRegion = $item.region;
                    } 
                    break;
                case "gdestChoose":
                    $scope.guide.destId = $item.code;
                    $scope.guide.podRegion = $item.region;
                    $scope.guide.destCountryCode = $item.countryCode;
                    break;
                case "gdeliverytoChoose":
                    $scope.guide.deliverytoId = $item.code;
                    break;    
                case "podChoose":
                    $scope.guide.booking.podId = $item.code;
                    if(!$scope.guide.booking.destId || !$scope.guide.id){
                        $scope.guide.booking.destId = $item.code;
                        $scope.guide.booking.destName = $item.name;
                    }
                    break;
                case "porChoose":
                    $scope.guide.booking.porId = $item.code;
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;
                    break;
                case "deliverytoChoose":
                    $scope.guide.booking.deliverytoId = $item.code;
                    break;                    
                case "polChoose":
                    $scope.guide.booking.polId = $item.code;
                    break;
            }
        }

        $scope.copyBookingPorts = function() {
            $scope.guide.serviceTerm = $scope.guide.booking.serviceTerm;
            $scope.guide.porName = $scope.guide.booking.porName;
            $scope.guide.polName = $scope.guide.booking.polName;
            $scope.guide.podName = $scope.guide.booking.podName;
            $scope.guide.destName = $scope.guide.booking.destName;
            $scope.guide.deliveryTo = $scope.guide.booking.deliveryTo;

            $scope.guide.porId = $scope.guide.booking.porId;
            $scope.guide.polId = $scope.guide.booking.polId;
            $scope.guide.podId = $scope.guide.booking.podId;
            $scope.guide.destId = $scope.guide.booking.destId;
            $scope.guide.deliverytoId = $scope.guide.booking.deliverytoId;

            angular.forEach($rootScope.ISSEA($scope.guide.shipmentType)?$rootScope.OPSPORTS.seaports:$rootScope.OPSPORTS.airports, function(data){
                if ($scope.guide.booking.polId == data.code){
                    $scope.guide.polRegion = data.region;
                }
                if ($scope.guide.booking.podId == data.code){
                    $scope.guide.podRegion = data.region;
                }
            })
            $scope.guide.mark = $scope.guide.booking.mark;
            $scope.guide.pkgsAndType = $scope.guide.booking.pkgsAndType;
            $scope.guide.goodsDiscription = $scope.guide.booking.goodsDiscription;
            $scope.guide.gwVol = $scope.guide.gwVol;
            $translate.refresh();
        }
        $scope.copyGuidePorts = function() {
            $scope.guide.booking.serviceTerm = $scope.guide.serviceTerm;
            $scope.guide.booking.porName = $scope.guide.porName;
            $scope.guide.booking.polName = $scope.guide.polName;
            $scope.guide.booking.podName = $scope.guide.podName;
            $scope.guide.booking.destName = $scope.guide.destName;
            $scope.guide.booking.deliveryTo = $scope.guide.deliveryTo;

            $scope.guide.booking.porId = $scope.guide.porId;
            $scope.guide.booking.polId = $scope.guide.polId;
            $scope.guide.booking.podId = $scope.guide.podId;
            $scope.guide.booking.destId = $scope.guide.destId;
            $scope.guide.booking.deliverytoId = $scope.guide.deliverytoId;

            $scope.guide.booking.mark = $scope.guide.mark;
            $scope.guide.booking.pkgsAndType = $scope.guide.pkgsAndType;
            $scope.guide.booking.goodsDiscription = $scope.guide.goodsDiscription;
            $scope.guide.gwVol = $scope.guide.gwVol;
            $translate.refresh();
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            layer.closeAll();
            if(hblsAmtCopy){
                hblsAmtCopy = null;
                layer.confirm($translate.instant('autopsApp.guide.copyHblsConfirm', {hbls: hblsAmtCopy}), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    Guide.copyHbls({
                        oldId: oldGuideId,
                        newId: $scope.guide.id
                    }, function(){                        
                        layer.msg($translate.instant('global.copySuccess'));
                    })
                    layer.close(index);
                }, function() {});
            }
            else if (notDeliveriedAlert ){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.newTips'));
            }
            else if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.home.noEtaAlert'));
            }
            else if (DateUtils.getDaysDiff($scope.guide.eta, $scope.guide.etd) < 0) {
                layer.msg($translate.instant('autopsApp.guide.etaLessThanEtd'));
            }
            else {
                layer.msg($translate.instant('global.messages.submitted'));
            }
            document.title = result.jobNum; 
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409){
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title:"Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            }
            else if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            } 
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            }
            else if (result.status == 423) {
                layer.msg($translate.instant('autopsApp.guide.jobNumConflictAlert'));
            } 
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
            
            $translate.refresh();
        };

        var notDeliveriedAlert = false;
        $scope.save = function() {
            if ($scope.isSaving){
                return;
            }            
            if ($rootScope.HAVENOAUTH("AU_AMEND_SHIPMENT")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_SHIPMENT');   
                return;
            }
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if (!$rootScope.IS_OP_CHECK() && $scope.guide.isLocked) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (angular.equals($scope.guide, guideCopy) && $scope.guide.id) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            };
            
            $scope.isSaving = true;
            if (!$scope.guide.submited){
                notDeliveriedAlert = true;
            }
            else {
                notDeliveriedAlert = false;
            }

            // var langName = $rootScope.RQ_CHECK($scope.guide);
            // if(langName){
            //     $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.requiredNullAlert', {param: langName}));
            //     return;
            // }
            
            $rootScope.AUTOFULLNODE($scope.guide);
            vendersInit();            
            $scope.guide.booking.bookingToName = $rootScope.account.companyName;            
            Guide.update($scope.guide, onSaveSuccess, onSaveError);
        };

        var deliverySuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            layer.closeAll();
            if(hblsAmtCopy){
                hblsAmtCopy = null;
                layer.confirm($translate.instant('autopsApp.guide.copyHblsConfirm', {hbls: hblsAmtCopy}), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    Guide.copyHbls({
                        oldId: oldGuideId,
                        newId: $scope.guide.id
                    }, function(){
                        
                        layer.msg($translate.instant('global.copySuccess'));
                    })
                    layer.close(index);
                }, function() {});
            }
            else {
                layer.msg($translate.instant('global.messages.submitted'));
            }  
            $translate.refresh();
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 2;
            guideReportDTO.reportType = 20;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO);

        };

        var deliveryError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } 
            else if(result.status === 403){
                $scope.guide.status = guideCopy.status;
                layer.msg($translate.instant('global.messages.creditOverLimit'));
                $translate.refresh();
            }
            else {
                $rootScope.ALERT_ERROR(result);
            }
        };

        $scope.deliveryGuide = function() {
            if ($scope.isSaving) {
                return;
            }
            if(!$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $scope.isSaving = true;
            vendersInit();
            if (!$scope.guide.opId || !$scope.guide.bizType || !$scope.guide.customerId || !$scope.guide.customerName) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.newGuideTips'));
                $scope.isSaving = false;
                return;
            }
            $scope.guide.status = "Requested";

            if (!$scope.guide.createdBy || !$scope.guide.companyId) {
                $scope.guide.createdBy = $rootScope.account.id;                
                $scope.guide.companyId = $rootScope.account.companyId;
            }
            var pageUrl = window.location.href;
            if(!pageUrl.endsWith("=")){
                pageUrl = pageUrl + "/dialog/" + $scope.guide.encodeId;
            }
            Guide.delivery({pageUrl: pageUrl}, $scope.guide, deliverySuccess, deliveryError);
        }

        var vendersInit = function() {
            if ($scope.guide.warehouseVid && !$scope.guide.warehouseName){
                $scope.guide.warehouseVid = null;
                $scope.guide.warehouseName = null;
            }

            if ($scope.guide.deliveryId && !$scope.guide.deliveryName){
                $scope.guide.deliveryId = null;
                $scope.guide.agentUserId = null; 
                $scope.guide.deliveryName = null;
                $scope.guide.deliveryEmail = null;
                $scope.guide.deliverySecret = null;
            }
            if ($scope.guide.bookingToId && !$scope.guide.bookingToName){
                $scope.guide.bookingToId = null;
                $scope.guide.bookingToName = null;
                $scope.guide.bookingToEmail = null;
            }
            if ($scope.guide.shipperId && !$scope.guide.shipperName){
                $scope.guide.shipperId = null;
                $scope.guide.shipperName = null;
                $scope.guide.shipperUserId = null;                    
            }
            if ($scope.guide.fumigationVid && !$scope.guide.fumigationName) {
                $scope.guide.fumigationVid = null;
                $scope.guide.fumigationName = null;
            }
            if ($scope.guide.insuranceVid && !$scope.guide.insuranceName) {
                $scope.guide.insuranceVid = null;
                $scope.guide.insuranceName = null;
            }
            if ($scope.guide.traderVid && !$scope.guide.traderName) {
                $scope.guide.traderVid = null;
                $scope.guide.traderName = null;
            }
        }

        $scope.updateFreightTerm = function(term) {
            $scope.guide.freightTerm = term;
            if(term == "PPD"){
                $scope.guide.payableAt = $scope.guide.booking.polName;
            }
            else {
                $scope.guide.payableAt = "DESTINATION";
            }
            
            $translate.refresh();
        }

        $scope.updateSupervisor = function(item) {
            $scope.guide.checkById = item.id;
            $scope.guide.checkByName = item.name;
        };
        $scope.updateOperator = function(item) {
            $scope.guide.opName = item.name;
            $scope.guide.booking.opId = item.id;
            $scope.guide.opId = item.id;
            // if(!$scope.guide.truckBy && item.authorities.indexOf("TRUCK_BY") !== -1) {
            //     $scope.guide.truckBy = item.name;
            //     $scope.guide.truckById = item.id;
            // }
            // if(!$scope.guide.customBy && item.authorities.indexOf("CUSTOM_BY") !== -1) {
            //     $scope.guide.customBy = item.name;
            //     $scope.guide.customById = item.id;
            // }
        };
        $scope.updateFin = function(item) {
            $scope.guide.finName = item.name;
            $scope.guide.booking.finId = item.id;
            $scope.guide.finId = item.id;
        };
        $scope.updateSales = function(item) {
            $scope.guide.salesName = item.name;
            $scope.guide.booking.salesId = item.id;
            $scope.guide.salesId = item.id;
            $scope.guide.team = item.team;
            $scope.guide.branch = item.branch;
        };
        $scope.updateCus = function(item) {
            $scope.guide.cusName = item.name;
            $scope.guide.cusId = item.id;
        };

        $scope.updateTruckBy = function(item) {
            $scope.guide.truckBy = item.name;
            $scope.guide.truckById = item.id;
        };
        $scope.updateCustomBy = function(item) {
            $scope.guide.customBy = item.name;
            $scope.guide.customById = item.id;
        };
        $scope.updateBookingBy = function(item) {
            $scope.guide.bookingBy = item.name;
            $scope.guide.bookingById = item.id;
        };

        $scope.addCtn = function() {
            $scope.guide.booking.bookingCtns.push({});
        };

        $scope.isWood = false;
        $scope.woodenTxt = function() {
            if ($scope.guide.isWood) {
                $scope.guide.woodenStatement = "THIS SHIPMENT HAS BEEN FUMIGATED AND HEATED.";
            } else {
                $scope.guide.woodenStatement = "THIS SHIPMENT CONTAINS NO SOLID WOODEN PACKING MATERIALS.";
            }
        }
        var isFcl = true;
        $scope.generateSayCtns = function(isSwitch, isHbl) {
            if(isSwitch){
                isFcl = !isFcl;  
            }
            else if ($scope.guide.shipmentType == "FCL"){
                isFcl = true;
            }
            if ($scope.guide.shipmentType == "FCL" && isFcl) {
                if (!$scope.guide.booking || !$scope.guide.guideFclCtns || $scope.guide.guideFclCtns.length == 0) {
                    layer.msg($translate.instant('global.messages.sayCtnsFailed'));
                    return;
                }
                var sayCtns = "SAY ";
                var isFirst = true;

                var ctnTypeSet = new Set();
                angular.forEach($scope.guide.guideFclCtns, function(data){
                    if(data.ctnType && !ctnTypeSet.has(data.ctnType)){
                        ctnTypeSet.add(data.ctnType);
                    }
                }) 

                var ctnCount = 0;
                ctnTypeSet.forEach(function(ctnType){
                    ctnCount = 0;
                    angular.forEach($scope.guide.guideFclCtns, function(data) {
                        if(data.ctnType == ctnType && data.num){
                            ctnCount += data.num;
                        }
                    })
                    if (ctnCount) {
                        if (!isFirst) {
                            sayCtns = sayCtns + " AND ";
                        }
                        sayCtns = sayCtns + DataUtils.translate(ctnCount.toString()) + " (" + ctnCount + ") " + ctnType;
                        isFirst = false;
                    }
                })
                $scope.guide.sayCtns = sayCtns + " CONTAINER(S) ONLY.";
            } else {
                var sayPkgs = isHbl?DataUtils.getNumFromStr($scope.guide.booking.pkgsAndType):DataUtils.getNumFromStr($scope.guide.pkgsAndType);
                if(!sayPkgs){
                    sayPkgs = $scope.guide.pkgNum;
                }
                if (!sayPkgs) {
                    layer.msg($translate.instant('global.messages.sayCtnsFailed'));
                    return;
                }
                $scope.guide.sayCtns = "SAY " + DataUtils.translate(sayPkgs.toString()) + " (" + sayPkgs + ") " + $scope.guide.booking.pkgType + " ONLY.";
            }            
        }
        $scope.uppercaseDescription = function(str) {
            if (!$scope.guide.booking.goodsDiscription) {
                $scope.guide.booking.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            } else {
                $scope.guide.booking.goodsDiscription = angular.uppercase(str);
            }
        }

        $scope.generateGoodsDiscription = function() {
            if (!$scope.guide.goodsDiscription) {
                if($scope.guide.shipmentType == "AIR"){
                    $scope.guide.goodsDiscription =  "CONSOL SHIPMENT \nAS MANIFEST ATT'D";
                }
                else if ($scope.guide.booking.goodsDiscription && !$scope.guide.mblOnly) {
                    $scope.guide.goodsDiscription = angular.copy($scope.guide.booking.goodsDiscription);
                }
                else if ($scope.guide.cargoName) {
                    $scope.guide.goodsDiscription = angular.uppercase($scope.guide.cargoName);
                }
            }
            $scope.guide.goodsDiscription = angular.uppercase($scope.guide.goodsDiscription);
        }
        $scope.generateMark = function() {
            if (!$scope.guide.mark && $scope.guide.booking.mark && !$scope.guide.mblOnly) {
                $scope.guide.mark = angular.copy($scope.guide.booking.mark);               
            }
            $scope.guide.mark = angular.uppercase($scope.guide.mark);
        }

        $scope.ggeneratePkgsAndType = function() {
            if (!$scope.guide.pkgNum || !$scope.guide.booking.pkgType || $scope.guide.pkgsAndType) {
                $scope.guide.pkgsAndType = angular.uppercase($scope.guide.pkgsAndType);
                return;
            }
            $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
        }

        $scope.generatePkgsAndType = function() {
            if (!$scope.guide.pkgNum || !$scope.guide.booking.pkgType || $scope.guide.booking.pkgsAndType) {
                $scope.guide.booking.pkgsAndType = angular.uppercase($scope.guide.booking.pkgsAndType);
                return;
            }
            $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
        }

        $scope.updatePkgsAndType = function(guidePkg) {
            if(!$scope.guide.mblConfirmedTime){
                $scope.guide.pkgsAndType = $scope.guide.pkgNum + " " + $scope.guide.booking.pkgType;
            }
            if(!$scope.guide.hblConfirmedTime){
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum + " " + $scope.guide.booking.pkgType;
            }         
            // if((!$scope.guide.booking.pkgNum || guidePkg) && !$scope.guide.otherTimeOne && !$scope.guide.masterId && !$scope.guide.parcelId){
            //     $scope.guide.booking.pkgNum = $scope.guide.pkgNum;
            // }
        }

        $scope.generateGwVol = function() {
            if($scope.guide.hblConfirmedTime){
                return;
            }
            if ($scope.guide.booking.gwVol) {
                $scope.guide.booking.gwVol = angular.uppercase($scope.guide.booking.gwVol);
                return;
            }
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + " KGS" + "\n" + $scope.guide.vol.toFixed(3)  + " CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.booking.gwVol = $scope.guide.vol.toFixed(3)  + " CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2)  + " KGS";
            };
        };

        $scope.ggenerateGwVol = function() {
            if($scope.guide.mblConfirmedTime){
                return;
            }
            if ($scope.guide.gwVol) {
                $scope.guide.gwVol = angular.uppercase($scope.guide.gwVol);
                return;
            }
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + " KGS" + "\n" + $scope.guide.vol.toFixed(3)  + " CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.vol.toFixed(3) + " CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + " KGS";
            };
        };

        $scope.blSend = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            var subjectNum = $scope.guide.booking.bookingNum;
            if (!subjectNum){
                if ($scope.guide.hblNum){
                    subjectNum = $scope.guide.hblNum;
                }
                else {
                    subjectNum = $scope.guide.jobNum;
                }                
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.blSendSubject');
            email.content = $translate.instant('entity.email.blSendContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
                if(!angular.equals(guideCopy, $scope.guide)){
                    $scope.save();
                }        
            }, function(result) {});
        }


        $scope.getMBLFile = function() {
            if ($scope.files.length > 0) {
                angular.forEach($scope.files, function(file, index) {
                    if (file.filename.indexOf('MBL') != -1) {
                        email.attachments.push(file);
                    }
                });
            }
        }

        var afterSendEmail = function() {
            if (emailType == "Do") {
                $scope.updateNode('DoReleased', 'soReleaseTime');
            }
        }

        $scope.updateHblRls = function(releaseWay) {
            if (releaseWay == "RLD") {
                layer.msg($translate.instant('autopsApp.guide.hblReleaseAlert'));
                $scope.guide.hblRlsWay = guideCopy.hblRlsWay;
                $translate.refresh();
                return;
            }

        }

        var fileName_index = 0;
        $scope.exportExcelPdf = function(format, code, fileName, idCopy) {
            if($rootScope.VIEW_GUIDE_FORBIDDEN(code)){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);  
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;            
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, fileName);
            });
        }

        /* ctns view */
        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
            $translate.refresh();
        };

        $scope.deleteGCtn = function(ctn) {
            if($scope.guide.isClosed){return;}
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            };
            $scope.refreshBookingCtns(ctn);
            $scope.guideCtnChange();
        };

        /* truck view */


        $scope.truckRequiredChange = function(required) {
            if (required) {
                if(!$scope.guide.truckBy){
                    $scope.guide.truckById = $scope.guide.opId;
                    $scope.guide.truckBy = $scope.guide.opName;
                }
            }
            else {
                $scope.guide.truckById = null;
                $scope.guide.truckBy = null;
            }
        }
        $scope.customRequiredChange = function(required) {
            if (required && !$scope.guide.customBy) {
                $scope.guide.customById = $scope.guide.opId;
                $scope.guide.customBy = $scope.guide.opName;
            }
        }

        $scope.smSend = function(ctn) {
            var entity = {};
            entity.ctn = ctn;
            entity.guide = $scope.guide;
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-delete-dialog.html',
                controller: 'BookingCtnsDeleteController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });            
        }

        $scope.lockAll = function() {
            for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                $scope.guide.guideCtns[i].locked = true;
            }
        }

        $scope.copyHblProp = function(fd) {
            $scope.guide[fd] = $scope.guide.booking[fd];
            if(fd == "polName"){
                $scope.guide.polId = $scope.guide.booking.polId;
            }
            else if(fd == "podName"){
                $scope.guide.podId = $scope.guide.booking.podId;
            }
            else if(fd == "destName"){
                $scope.guide.destId = $scope.guide.booking.destId;
            }
        }

        $scope.splitHBLView = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var splitGuide = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/guide/split-bl.html',
                controller: 'SplitBlController',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return splitGuide;
                    }]
                }
            }).result.then(function(result) {
                $uibModal.open({
                    templateUrl: 'app/entities/import/import-dialog.html',
                    controller: 'ImportDialogController',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return Guide.get({id : result.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                }, function(result) {
                });
            }, function() {});
        }


        $scope.lockSI = function() {
            if ($scope.guide.booking.isLocked) {
                $scope.guide.booking.isLocked = false;
            } else {
                $scope.guide.booking.isLocked = true;
            }
            Booking.lockBooking({
                bookingId: $scope.guide.booking.id,
                isLocked: $scope.guide.booking.isLocked
            }, function(result, headers) {
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        $scope.refreshCtns = function() {
            if (!$scope.guide.guideFclCtns) {
                return;
            }
            var ctnTypes = new Set();
            var size = 0;
            //添加
            angular.forEach($scope.guide.guideFclCtns, function(bctn){
                if (bctn.ctnType && bctn.num && bctn.ctnType.length > 3) {
                    size = 0;
                    angular.forEach($scope.guide.guideCtns, function(gctn){
                        if (gctn.ctnType == bctn.ctnType) {
                            size++;
                        }
                    })
                    if(size < bctn.num){
                        for (var j = 0; j < bctn.num - size; j++) {
                            $scope.guide.guideCtns.push({
                                ctnType: bctn.ctnType
                            });
                        }
                    }  
                    ctnTypes.add(bctn.ctnType);
                }
            })
            //删除不存在的柜型
            for (var ii = $scope.guide.guideCtns.length - 1; ii >= 0; ii--) {
                if(!$scope.guide.guideCtns[ii].ctnType || !ctnTypes.has($scope.guide.guideCtns[ii].ctnType)){
                    $scope.deleteGCtn($scope.guide.guideCtns[ii]);
                    continue;
                }
            }        
            //删除多余的柜型
            angular.forEach($scope.guide.guideFclCtns, function(bctn){
                size = 0;
                angular.forEach($scope.guide.guideCtns, function(gctn){
                    if (gctn.ctnType == bctn.ctnType) {
                        size++;
                    }
                })
                if(!bctn.num){bctn.num = 0;}
                size = size - bctn.num;
                if(size > 0){
                    for (var ii = $scope.guide.guideCtns.length - 1; ii >= 0; ii--) {
                        if (size > 0 && $scope.guide.guideCtns[ii].ctnType == bctn.ctnType){
                            $scope.deleteGCtn($scope.guide.guideCtns[ii]);
                            size--;
                        }
                    }
                }
            })
            $scope.generateSayCtns();
        }


        $scope.refreshBookingCtns = function(ctn) {
            if ( $scope.guide.shipmentType != "FCL" || !ctn || !ctn.ctnType || ctn.ctnType.length < 4) {
                return;
            }
            var ctnTypes = new Set();
            angular.forEach($scope.guide.guideCtns, function(gctn){
                ctnTypes.add(gctn.ctnType);
            })
            for (var ii = $scope.guide.guideFclCtns.length - 1; ii >= 0; ii--) {
                if (!ctnTypes.has($scope.guide.guideFclCtns[ii].ctnType)){
                    $scope.deleteFclCtn($scope.guide.guideFclCtns[ii]);
                }
            }    
            var size = 0;
            var sizes = {};
            ctnTypes.forEach(function(ctnType){
                size = 0;
                angular.forEach($scope.guide.guideCtns, function(gctn){
                    if (gctn.ctnType == ctnType) {
                        size++;
                    }
                })
                if(size){
                    sizes[ctnType] = size;
                }      
            })            
            if(!$scope.guide.guideFclCtns){
                $scope.guide.guideFclCtns = [];
            }
            var matched = false;
            ctnTypes.forEach(function(ctnType){
                matched = false;
                angular.forEach($scope.guide.guideFclCtns, function(bctn){
                    if(bctn.ctnType == ctnType){
                        bctn.num = sizes[ctnType];
                        matched = true;
                    }
                })
                if(!matched){
                    $scope.guide.guideFclCtns.push({
                        num : sizes[ctnType],
                        ctnType : ctnType
                    });
                }
            })
        }

        $scope.combineHblIntoMbl = function(refreshDes) {
            if(!$scope.hbls){return;}
            Booking.getHbls({refGuideId: $scope.guide.id}, function(result){
                var gw = 0,//件毛体合并
                    pkgs = 0,
                    vol = 0,
                    chargeWeight = 0,
                    isSamePkg = true,
                    pkgType;
                if(refreshDes){
                    $scope.guide.mark = "";
                    $scope.guide.goodsDiscription = "";
                }
                angular.forEach(result, function(data) {
                    if (data.pkgNum) {
                        pkgs = pkgs + data.pkgNum;
                    }
                    if (data.gw) {
                        gw = gw + data.gw;
                    }
                    if (data.vol) {
                        vol = vol + data.vol;
                    }
                    if (data.chargeWeight) {
                        chargeWeight = chargeWeight + data.chargeWeight;
                    }
                    if (!pkgType && data.pkgType) {
                        pkgType = data.pkgType;
                    }
                    if (data.pkgType && pkgType != data.pkgType) {
                        isSamePkg = false;
                    }
                    if(refreshDes && data.mark){
                        $scope.guide.mark += data.mark + "\n";
                    }
                    if(refreshDes && data.goodsDiscription){
                        $scope.guide.goodsDiscription += data.goodsDiscription + "\n";
                    }
                })
                $scope.guide.pkgNum = pkgs;
                if(!$scope.guide.cwLoked){
                    $scope.guide.chargeWeight = chargeWeight;
                    $scope.guide.gw = gw;
                    $scope.guide.vol = vol;
                }
                if (isSamePkg) {
                    $scope.guide.booking.pkgType = pkgType;
                } else {
                    $scope.guide.booking.pkgType = "PKGS";
                }

                if($scope.guide.shipmentType == "FCL"){//柜型柜量合并
                    var ctnNums = new Set();  //所有柜号
                    angular.forEach(result, function(booking) {
                        angular.forEach(booking.guideCtns, function(ctn) {
                            if (ctn.ctnNum) {
                                ctnNums.add(ctn.ctnNum);
                            }
                        })
                    });
                    var ctnCopy;
                    ctnNums.forEach(function(ctnNum){//s.has(100);//返回bool结果，true表示有，false表示无
                        gw = 0;
                        pkgs = 0;
                        vol = 0;
                        isSamePkg = true;
                        pkgType = null;
                        ctnCopy = null;
                        angular.forEach(result, function(booking) {
                            angular.forEach(booking.guideCtns, function(data) {
                                if (data.ctnNum == ctnNum) {
                                    if (data.pkgs) {
                                        pkgs = pkgs + data.pkgs;
                                    }
                                    if (data.gw) {
                                        gw = gw + data.gw;
                                    }
                                    if (data.vol) {
                                        vol = vol + data.vol;
                                    }
                                    if (!pkgType && data.pkgType) {
                                        pkgType = data.pkgType;
                                    }
                                    if (data.pkgType && pkgType != data.pkgType) {
                                        isSamePkg = false;
                                    }
                                    ctnCopy = angular.copy(data);
                                }
                            })
                        })
                        var isNewCtn = true;
                        angular.forEach($scope.guide.guideCtns, function(gctn) {
                            if (gctn.ctnNum == ctnNum) {
                                gctn.pkgs = pkgs;
                                gctn.gw = gw;
                                gctn.vol = vol;
                                if (isSamePkg) {
                                    gctn.pkgType = pkgType;
                                } else {
                                    gctn.pkgType = "PKGS";
                                }
                                isNewCtn = false;
                            }
                        })
                        if(isNewCtn && ctnCopy){
                            var newCtn = {};
                            newCtn.pkgs = pkgs;
                            newCtn.gw = gw;
                            newCtn.vol = vol;
                            if (isSamePkg) {
                                newCtn.pkgType = pkgType;
                            } else {
                                newCtn.pkgType = "PKGS";
                            }
                            newCtn.ctnType = ctnCopy.ctnType;
                            newCtn.sealNum = ctnCopy.sealNum;
                            newCtn.ctnNum = ctnCopy.ctnNum;
                            $scope.guide.guideCtns.push(newCtn);
                        }
                    });
                }
                $scope.updateGwVol();
                if($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0){
                    $scope.refreshBookingCtns($scope.guide.guideCtns[0]);
                }    
                $translate.refresh();
                layer.msg($translate.instant('autopsApp.booking.home.combineHblsQuestion'));
            })
        }

        $scope.getHblsCount = function() {
            Booking.getHblsCount({
                guideId: $scope.guide.id
            }, function(result, headers) {
                $scope.hbls = result.hbls;
            });
        }


        $scope.applyRelease = function() {
            guideCopy.isApplied = !guideCopy.isApplied;
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            };
            guideCopy.isApplied = !guideCopy.isApplied;
            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if(!$scope.guide.finId){
                layer.msg($translate.instant('autopsApp.guide.home.finNullAlert'));  
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if ($scope.guide.hblReleasedTime && $scope.guide.isApplied) {
                layer.msg($translate.instant('autopsApp.guide.home.blrelased'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            $scope.isSaving = true;
            Guide.applyRelease({
                guideId: $scope.guide.id,
                isApplied: $scope.guide.isApplied
            }, function(result, headers) {
                loadGuide();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        $scope.updateGwVol = function() {
            DataUtils.updateGuideCw($scope.guide);
        };
        $scope.priceChange = function() {
            var ttlPrice = 0;
            var ttlPieces = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(data) {
                if(data.unitPrice != null && data.pieces != null){
                    data.totalPrice = data.unitPrice * data.pieces;
                    data.totalPrice = DataUtils.round(data.totalPrice, 2);
                    ttlPrice = ttlPrice + data.totalPrice;
                }       
                if(data.pieces){
                    ttlPieces = ttlPieces + data.pieces;
                }
                
            })
            $scope.guide.cargoValue = ttlPrice + "USD";
            $scope.guide.pieces = DataUtils.round(ttlPieces, 2);
        };

        $scope.chargeWeightChange = function(chargeWeight) {
            $scope.guide.awbWeight = chargeWeight;
        };

        $scope.guideCtnChange = function() {
            DataUtils.guideCtnChange($scope.guide);
        }

        $scope.deleteGuide = function (){
            layer.confirm($translate.instant('autopsApp.guide.delete.question', {id: $scope.guide.jobNum}), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                Guide.delete({id: $scope.guide.id}, function () {
                    layer.close(index);
                    layer.msg($translate.instant('global.delete_success'));
                    goBack();
                });                
            }, function() {});
        }

        $scope.setVoid = function() {
            if($rootScope.HAVENOAUTH('AU_VOID_ORDER')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_VOID_ORDER'); 
                return;
            }  
            if ($scope.guide.isVoid) {
                Guide.setVoid({id:$scope.guide.id}, function(result) {
                    loadGuide();                    
                    layer.msg($translate.instant('global.valid_success'));
                }, $rootScope.ALERT_ERROR);
            } else {
                if (!angular.equals(guideCopy, $scope.guide)) {
                    layer.msg($translate.instant('global.saveAlert'));
                    return;
                };
                layer.confirm($translate.instant('autopsApp.guide.delAllRatesAlert'), {
                    icon: 3,
                    title: $translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    Guide.setVoid({id:$scope.guide.id}, function(result) {
                        loadGuide();                    
                        layer.msg($translate.instant('global.invalid_success'));
                    }, $rootScope.ALERT_ERROR);
                }, function() {
                });
            }
        }

        $scope.setIsClosed = function() {
            if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $scope.guide.isClosed = !$scope.guide.isClosed;
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');   
                return;
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                $scope.guide.isClosed = !$scope.guide.isClosed;
                return;
            };
            if (!$scope.guide.isClosed) {
                if ($rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                    $scope.guide.isClosed = !$scope.guide.isClosed;
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');   
                    return;
                }
                $scope.guide.isClosed = false;
                $scope.guide.status = "Restored";
            } else {
                $scope.guide.isClosed = true;
                $scope.guide.status = "isClosed";
            }
            Guide.update($scope.guide, function(result) {
                $scope.guide = result;
                guideCopy = angular.copy($scope.guide);
                $scope.$emit('autopsApp:guideUpdate', guideCopy);
                layer.msg($translate.instant('global.save_success'));
                if(result.masterId == 0){
                    layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.msg($translate.instant('global.isSavingMsg'), {
                              shade: 0.3,
                              time: 200000
                            });    
                            Guide.lockSubGuidesAll({
                                masterId: result.id,
                                bValue: result.isClosed,
                                type: "guide"
                            }, function(result) {
                                layer.closeAll();
                                layer.msg($translate.instant('global.save_success'));
                            })
                            layer.close(index);
                        }, function() {
                    });
                }
            }, onSaveError);
        }


        $scope.checkPop = function() {
            var guideCheckStatus = $scope.guide.checkStatus;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/guideRates-dialog.html',
                controller: 'GuideRatesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }]
                }
            }).result.then(function(result) {
                guide.checkStatus = result.checkStatus;
            }, function(result) {
                $scope.guide.checkStatus = guideCheckStatus;
            });
        };

        $scope.changeMblNum = function() {
            if (!$scope.guide.etdLocked){
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.prompt({
                formType: 0,
                value: $scope.guide.mblNum,
                title: $translate.instant('autopsApp.guide.changeMblNum')
            }, function(value, index, elem) {
                layer.close(index);
                value = value.replace(/\s+/g, "");
                Guide.amendMblNumByOp({
                    guideId: $scope.guide.id,
                    mblNum: value
                }, function(result) {
                    loadGuide()     
                    layer.msg($translate.instant('global.messages.submitted'));
                 }, function(result) {
                    layer.msg($translate.instant('global.messages.submittedfailed'));                  
                });
            });
        }
        $scope.updateVol = function(bookingCtn) {
            if (bookingCtn.length && bookingCtn.width && bookingCtn.height) {
                bookingCtn.width = bookingCtn.width.toFixed(4) - 0;
                bookingCtn.length = bookingCtn.length.toFixed(4) - 0;
                bookingCtn.height = bookingCtn.height.toFixed(4) - 0;
                bookingCtn.vol = bookingCtn.length * bookingCtn.width * bookingCtn.height / 1000000;
                bookingCtn.vol = DataUtils.round(bookingCtn.vol, 5);
                $scope.volChange(bookingCtn);
            }
        };
        $scope.updateVolGw = function() {
            DataUtils.updateGuidePl($scope.guide);
        }
        $scope.gwChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.gw){
                bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 4);
                $scope.updateVolGw();
            }
        };
        $scope.volChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.vol){
                bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                $scope.updateVolGw();
            }
        };
        $scope.pkgChange = function(bookingCtn) {
            if(bookingCtn.pkgNum){
                if(bookingCtn.vol){
                    bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                }
                if(bookingCtn.gw){
                    bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 4);
                }
                $scope.updateVolGw();
            }
        };
        $scope.onCarrierSelected = function(carrier) {       
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.carrierFullName;
            if($scope.guide.shipmentType == "AIR" && !$scope.guide.mblNum){
                $scope.guide.mblNum = carrier.remarks + "-";
            }
            $rootScope.UPDATE_BY_CARRIER(carrier, 'guide', $scope.guide);
        }

        $scope.amendEtd = function(amendBillDate) {
            if (!$rootScope.IS_OP_CHECK() && $scope.guide.isLocked) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (!$scope.guide.etdLocked && !amendBillDate) {
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };            
            var guideDto = {};
            guideDto.id = $scope.guide.id;
            guideDto.etd = $scope.guide.etd;
            if(amendBillDate && $scope.guide.billDate){
                guideDto.etd = $scope.guide.billDate;
            }
            guideDto.lockEDate = true;
            guideDto.amendBillDate = amendBillDate;
            $uibModal.open({
                templateUrl: 'app/entities/guide/amend-etd.html',
                controller: 'AmendEtdController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guideDto;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
                $translate.refresh();
            }, function() {});
        }

        $scope.previewReport = function(code, truckId) {
            if($rootScope.VIEW_GUIDE_FORBIDDEN(code)){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;            
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }


        $scope.getLoadRemark = function() {
            if(!$scope.guide.booking.loadRemark){
                var add = "预计货到堆场时间：" + DateUtils.convertLocalDateToServer($scope.guide.cargoReadyDate) + "\n\n";
                add = add + "装箱要求：" + "\n";
                add = add + "报箱号时间：" + "\n";
                add = add + "费用：" + "\n";
                $scope.guide.booking.loadRemark = add;    
            }
        }

        $scope.checkCtnRepeat = function(ctn) {
            if(!ctn.ctnNum){
                return;
            }
            angular.forEach($scope.guide.guideCtns, function(data){
                if (data != ctn && data.ctnNum == ctn.ctnNum){
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }

        $scope.datePickerForDeclareDate = {};
        $scope.datePickerForDeclareDate.status = {
            opened: false
        };
        $scope.datePickerForDeclareDateOpen = function($event) {
            $scope.datePickerForDeclareDate.status.opened = true;
        };
        $scope.blAttRemark = function(hm) {
            if (hm == "H"){
                $scope.guide.booking.goodsDiscription = $scope.guide.booking.goodsDiscription + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
            }
            else {
                $scope.guide.goodsDiscription = $scope.guide.goodsDiscription + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
            }
        }
        $rootScope.LOAD_REPORT_TEMPLATES();
        $scope.sendAn = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.anSubject');
            email.content = $translate.instant('entity.email.anContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.sendDevanning = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.dvSubject');
            email.content = $translate.instant('entity.email.dvContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 490;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.sendGuarantee = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.grtSubject');
            email.content = $translate.instant('entity.email.grtContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 500;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.sendDO = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.doSubject');
            email.content = $translate.instant('entity.email.doContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.contactId = $scope.guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.inputNcv = function() {
            if (!$scope.guide.ncvStr){
                $scope.guide.ncvStr = "NCV";
            }
        }
        $scope.synCtns = function() {           
            Guide.synCtns($scope.guide, function(result) {
                if (result && result.length > 0){
                    angular.forEach(result, function(data){
                        $scope.guide.guideCtns.push(data);
                    })
                    layer.msg($translate.instant('autopsApp.guide.synSuccess'));
                }
                else {
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
            }, function(result){
                layer.msg($translate.instant('autopsApp.guide.synFailed'));
            });
        };

        $scope.hblView = function() {
            var entity = {};
            entity.guide = $scope.guide;
            $uibModal.open({
                templateUrl: 'app/entities/booking/booking-dialog.html',
                controller: 'BookingDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;                        
                    }]
                }
            }).result.then(function(result) {
                $scope.getHblsCount();
            }, function(result) {
                $scope.getHblsCount();
            });
        };

        $scope.importHGuides = function (file) {            
            if (!file) {return;}
            var fd = new FormData();
            fd.append('guideId', $scope.guide.id);
            fd.append('isExport', false);
            fd.append('file', file);
            $http.post(
                'api/guides/importExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    if (data.msg == "refreshRequired"){
                        layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                            title:"Msg",
                            btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            loadGuide();
                            layer.close(index);
                        }, function() {});
                    }
                    else {
                        layer.msg($translate.instant('global.importSuccess'));
                    }
                }).error(function(error) {
                    if (error.msg){
                        $rootScope.OPS_ALERT(error.msg);
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('global.importFailed'));       
                    }
                    
            });
        };

        $scope.importCtns = function (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/guides/importCtns', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    data = angular.fromJson(data);
                    angular.forEach(data, function(ctn){
                        $scope.guide.guideCtns.push(ctn);
                    })                    
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    if (error.msg){
                        $rootScope.OPS_ALERT(error.msg);
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('global.importFailed'));       
                    }                    
            });
        };

        KEYBOARD = "importGuideDialog";
        var saved = false;
        $(document).keydown(function(event) {            
            if( KEYBOARD != "importGuideDialog"){
                return;
            }
            else if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                if (saved){
                    return;
                }
                else {
                    saved = true;
                    $scope.save();
                }                
            } 
        });
        $scope.$on('modal.closing', function(event, reason, closed){
            if(reason == "escape key press"){
                event.preventDefault(); //You can use this to prevent the modal from closing       
                $scope.goBack();              
            }
        });
        $scope.excelExport = function() {
            var guides = [];
            guides.push($scope.guide);
            var fileName = $translate.instant('autopsApp.guide.packingList') + ".xlsx";           
            OpsFiles.exportPackingList({
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    
        $scope.amendDoc = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.bookingToName;
            var subjectNum = $scope.guide.mblNum;

            email.subject = subjectNum + "-- Amend Doc Application";
            email.content = "MBL#: " + $scope.guide.mblNum + ", pld kindly find the doc amendment application from the attachment. \n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 700;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.bookingToId;
            entity.contactId = $scope.guide.bookingToContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.importPlByExcel = function (file) {
            if (!file) {return;}
            if (!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($scope.guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }    
            var fd = new FormData();
            fd.append('file', file);
            fd.append('guideId', $scope.guide.id);
            $http.post(
                'api/bookingCtnss/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    loadGuide(true);          
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };

        $scope.ratesPop = function() {      
             if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };    
            $rootScope.GUIDE_RATES_POP($scope.guide); 
        } 

        $scope.setActiveTab = function (index) {
            if(index == 1){
                $scope.activeTab = activeTabCopy;
            }
            else {
                activeTabCopy = index;
            }            
        }
        $scope.isInvalid = function () {
            if($rootScope.account.companyId == 152 || $rootScope.account.companyId == 153 || $rootScope.account.companyId == 162){
                return false;
            }
            if(!$scope.guide 
                || !$scope.guide.submited
                || $scope.guide.isVoid){
                return true;
            }
            if($rootScope.account.mustAudit && $scope.guide.checkStatus != 'approved'){
                return true;
            }
            return false;
        }
        $scope.loadByJobNum = function() {
            $scope.isSaving = true;
            Guide.getGuideByJobNumAndType({
                companyId: $scope.guide.companyId,
                jobNum: $scope.jobNumCopy,
                type: $scope.guide.shipmentType,
                isExport: $scope.guide.isExport
            }, function(result) {
                $scope.guide = result;
                initGuide();
                $scope.isSaving = false;
                layer.msg($translate.instant('entity.action.loadSuccess'));
                $scope.files = [];
                $scope.clientFiles = [];
            }, function(result){
                layer.msg($translate.instant('global.loadFailed'));
            })
        }
        $scope.factoriesPop = function() {
            $uibModal.open({
                templateUrl: 'app/entities/opsFactory/opsFactory-dialog.html',
                controller: 'OpsFactoryDialogController',
                size: 'md',
                resolve: {
                    entity: function() {
                        return $scope.guide;
                    }
                }
            }).result.then(function(result) {

            }, function() {

            })
        }

        $scope.antiTempRegistIn = function(bCtn) {
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if($rootScope.HAVENOAUTH('AU_WMS_ANTI')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_ANTI');   
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var wmsGuide;
            if(bCtn){
                wmsGuide = {};
                wmsGuide.id = $scope.guide.id;
                wmsGuide.booking = {};
                wmsGuide.booking.bookingCtns = [];
                wmsGuide.booking.bookingCtns.push(bCtn);
            }
            else {
                wmsGuide = angular.copy($scope.guide);
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.antiInAlert'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    WmsIn.antiTempRegistIn(wmsGuide, function(){
                        layer.msg($translate.instant('global.messages.submitted'));
                        loadGuide();                   
                    }, function(result){
                        loadGuide(); 
                        if(result.status == 403){
                            $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.cargoIsOutAlert'));
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }                        
                    });                 
                }, function() {
            });  
        }

        $scope.saveTempRegistIn = function(fromExport) {
            if(fromExport && !$scope.guide.agentGuideId){
                layer.msg($translate.instant('autopsApp.guide.notFromExport'));
                return;
            }
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if(!$scope.guide.customerId || !$scope.guide.whId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseClientAndWarehouseTips'));                
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-register.html',
                controller: 'WmsInRegisterController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WmsIn', function(WmsIn) {
                        return WmsIn.getWmsInByGuide({isOut: false, fromExport: fromExport}, angular.copy($scope.guide)).$promise;
                    }]
                }
            }).result.then(function() {
                loadGuide();
            }, function() {
                loadGuide();
            });
        }
        $scope.wmsLabels = function(reportType) {
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if($scope.guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            var wmsIn = {};
            wmsIn.guideId = $scope.guide.id;
            wmsIn.companyId = $scope.guide.companyId;
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.printIn = function(reportType){
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };  

            var wmsIn = {isOut:false};
            wmsIn.guideId = $scope.guide.id;

            OpsFiles.generateWmsInPaper({reportType: reportType},wmsIn,function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }
        $scope.plConfirmedChange = function() {
            if($rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP') && $scope.guide.plConfirmTime){
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP'); 
                $scope.guide.plConfirmed  = true;
                return;            
            }
            if(!$scope.guide.otherTimeTwo && !$scope.guide.otherTimeOne){
                layer.msg($translate.instant('autopsApp.booking.wmsNotInAlert'));
                return;
            }
            if ($scope.guide.plConfirmed) {
                $scope.guide.plConfirmTime = new Date();
                $scope.guide.status = "plConfirmed";
            }
            else {
                $scope.guide.plConfirmTime = null;
                $scope.guide.status = guideCopy.status;
            }
        }
        $scope.wmswarehouses = null;
        $scope.getWarehouses = function() {
            if ($scope.wmswarehouses){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                $scope.wmswarehouses = data;
            });
        }        
        $scope.wmsWarehouseSelected = function(warehouse) {
            $scope.guide.whName = warehouse.name;
            $scope.guide.whId = warehouse.id;
        }
        $scope.getCargo = function() {
            if($scope.guide.masterId == 0){                
                layer.msg($translate.instant('autopsApp.guide.masterGetCargoAlert'));
                return;
            }
            if (!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var entt = {};
            entt.guide = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-dialog.html',
                controller: 'ExpressCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                loadGuide(true);                
            }, function() {
            });
        }
        $scope.synEtdToHbls = function() {
            if(!$scope.guide.id){
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('autopsApp.guide.synEtdToHbls') + "?", {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    Guide.synEtdToHbls($scope.guide, function(){
                        layer.msg($translate.instant('global.syn_success'));             
                    }, function(result){
                        layer.msg($translate.instant('global.syn_fail'));                      
                    });                  
                }, function() {
            });  
        }
        // $scope.getTrucksAndBroker = function() {
        //     if(!$scope.guide.booking.id){
        //         return;
        //     }
        //     Booking.getTrucksAndBroker({id: $scope.guide.booking.id}, function(result){
        //         $scope.bookingTrucks = result.bookingTrucks;
        //     })
        // }
        // var unsubscribeTruck = $rootScope.$on('autopsApp:bookingTruckUpdate', function(event, result) {
        //     if($scope.bookingTrucks && $scope.bookingTrucks.length > 0){
        //         angular.forEach($scope.bookingTrucks, function(data){
        //             if(data.id == result.id){
        //                 angular.copy(result, data);
        //             }
        //         })
        //     }
        //     else {
        //         $scope.bookingTrucks = [];
        //         $scope.bookingTrucks.push(result);
        //     }
        // });
        // $scope.$on('$destroy', unsubscribeTruck);

        $scope.updateNode = function(status, fd) {
            if(!$rootScope.IS_OP_CHECK()){
                layer.msg($translate.instant('autopsApp.guide.opDocEnterAlert'));                
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(status == "status"){
                if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');   
                    return;
                }
                if (!$scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE'); 
                    return;
                }
            }
            if($scope.guide[fd] || $scope.guide.booking[fd]){
                if($rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP') && status != 'Cancelled'){
                    $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP');   
                    return;            
                }
                layer.confirm($translate.instant('autopsApp.guide.clearNodeAlert'), {icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        Guide.setStatusOne({
                            guideId: $scope.guide.id,
                            status: status
                        }, function(result) {
                            layer.msg($translate.instant('global.save_success'));
                            loadGuide();    
                        }, function(result) {
                            layer.closeAll();
                            $scope.isSaving = false;
                            $rootScope.ALERT_ERROR(result);                        
                        });
                        layer.close(index);
                    }, function() {
                });
                return;
            }
            var entity = {};
            entity.guide = angular.copy($scope.guide);
            entity.status = status;
            $uibModal.open({
                templateUrl: 'app/entities/ops-track-status/ops-track-status-detail.html',
                controller: 'OpsTrackStatusDetailController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                layer.msg($translate.instant('global.save_success'));
                loadGuide();         
            }, function() {
            });
        }


        $scope.deliveryWayChange = function() {
            // if($scope.guide.deliveryWay == "express" && $scope.guide.shipmentType != "LOCAL" && $scope.guide.shipmentType != "WMS"){
            //     $scope.carriers = $rootScope.OPSPORTS.expressLiners;
            // }
        };
        $scope.customsPop = function() {
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.customsMgmt($scope.guide);
        };
        
        var unsubscribeGuide = $rootScope.$on('autopsApp:loadGuide', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }   
        });
        $scope.$on('$destroy', unsubscribeGuide);

        $scope.addFclCtn = function() {
            if(!$scope.guide.guideFclCtns){
                $scope.guide.guideFclCtns = [];
            }
            $scope.guide.guideFclCtns.push({});
            $translate.refresh();
        };
        $scope.deleteFclCtn = function(fclCtn) {
            if($scope.guide.isClosed){return;}
            if (!angular.equals(guideCopy, $scope.guide) && $scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (fclCtn.id && $scope.guide.id) {
                var loadIndex = layer.load(1, {shade: 0.3});
                GuideFclCtn.delete({
                    id: fclCtn.id
                }, function(){
                    layer.close(loadIndex);  
                    loadGuide();
                }, function(result){
                    layer.close(loadIndex);  
                    layer.msg($translate.instant('global.delete_fail'));
                });
            }
            else {
                var index = $scope.guide.guideFclCtns.indexOf(fclCtn);
                if (index > -1) {
                    $scope.guide.guideFclCtns.splice(index, 1);
                };
            }
        };
        $scope.updateByFclCtn = function() {
            DataUtils.updateByFclCtn($scope.guide);
        }

        $scope.authorizeBranch = function() {
            if (!$scope.guide.bookingToId) {
                layer.msg($translate.instant('autopsApp.guide.authorizeBranchNull'));
                return;
            }
            if(!$scope.guide.submited){
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            }
            var title = $translate.instant('autopsApp.guide.authorizeBranch');

            var content = $scope.guide.bookingToName + $translate.instant('autopsApp.guide.home.auth_send_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if($scope.guide.masterId == 0){
                    var sendSubOrderTip = $translate.instant('autopsApp.guide.home.isSendSubOrder');
                    layer.confirm(sendSubOrderTip, {
                        title: title,
                        btn: [$translate.instant('autopsApp.guide.home.includeSubOrder'), $translate.instant('autopsApp.guide.home.onlyMasterOrder')]
                    }, function(){
                        $scope.isSaving = true;
                        Guide.authorizeBranch({sendSubOrder: true}, $scope.guide, onSendSuccess, onAuthError);
                    }, function(){
                        $scope.isSaving = true;
                        Guide.authorizeBranch({sendSubOrder: false}, $scope.guide, onSendSuccess, onAuthError);
                    })
                }else{
                    $scope.isSaving = true;
                    Guide.authorizeBranch($scope.guide, onSendSuccess, onAuthError);
                }
            }, function() {
                return;
            });
        }

        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', guideCopy);
            layer.closeAll();
            layer.msg($translate.instant('global.messages.submitted'));
        };
        var onAuthError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.authErrorAlert'));
            }
            else if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.guide.entrustConflict'));
            }
            else if (result.status === 403) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        var onSynSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            layer.msg($translate.instant('autopsApp.guide.synSuccess'));
        };

        var onSynFailed = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('autopsApp.guide.synFailed'));
        };
        $scope.synBranchGuide = function() {
            if($rootScope.HAVENOAUTH("SYN_EDI_DATA")){
                $rootScope.FORBIDDEN_BY_ALERT('SYN_EDI_DATA'); 
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $scope.isSaving = true;
            Guide.synBranchGuide($scope.guide, onSynSuccess, onSynFailed);
        }
        $scope.unbind = function() {
            layer.confirm($translate.instant('autopsApp.guide.unbindAlert'), {
                title: $translate.instant('global.messageInfo'),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                Guide.unbind({
                    guideId: $scope.guide.id,
                    branchGuideId: $scope.guide.branchGuideId
                }, function(result) {
                    $scope.guide.branchGuideId = null;
                    layer.close(index);
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }
        $scope.deletePlByGuideId = function() {
            var loadIndex = layer.load(1, {shade: 0.3});
            BookingCtns.deletePlByGuideId({
                guideId: $scope.guide.id
            }, function(){
                layer.close(loadIndex);  
                loadGuide();
            }, function(result){
                layer.close(loadIndex);  
                if(result.status == 409){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});                        
                }
                else {
                    layer.msg($translate.instant('global.delete_fail'));
                }
            });
        };

        $scope.getCustomExcelImportRecord = function() {
            if ($scope.customExcelImportRecords) {
                return;
            }
            GridState.getCustomExcelImportRecord({types: Array.from(arguments)}, function(result) {
                $scope.customExcelImportRecords = result;
            })
        }

        $scope.importImportOrdersByCustomize = function(customExcelImportRecord, file) {
            if ($scope.guide.id && !angular.equals($scope.guide, guideCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.importOrdersByCustomize(customExcelImportRecord, file, $scope.guide)
        }
        var unsubscribeUpdateByChannel = $rootScope.$on('autopsApp:guideUpdateByChannel', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }   
        });
        $scope.$on('$destroy', unsubscribeUpdateByChannel);
        $scope.fmsRapidIn = function() {
            if (!$scope.guide.id || !angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.FMS_RAPID_IN($scope.guide.shipmentType, $scope.guide.id);
        }   
        
    }
})();